// import { getUserInfo } from "@/utils/auth";
import { kqdId,serviceId } from "@/utils/consts"
import { getLocalUserId } from '@/utils/tool';
import { everydayGreet } from '@/api/user';

export let pending = false // 判断是否请求中

const state = {
  leaveMessageUpdate: false,  // 重新加载留言列表
  leaveMessageClickId: '',  // 打开留言窗口时点击的Id
  leaveMessageClickInfo: {},  // 留言窗口对象的信息
  leaveMessageVisible: false, // 留言窗口组件
  messagePending: false, // 判断是否请求中
  firstWindowVisible: false,  // 初次创建弹窗显示
  createWindowVisible: false, // 创建身份窗口显示
  openServiceWindow: false, // 打开关闭客服窗口
  closeServiceWindow: false,  // 强制关闭客服窗口
  showServiceWindow: true,  // 是否展现客服按钮
  demandInfo: {  // 承接需求内容
    id: '',
    title: ''
  },
  otherInfo: {  // 动态/新闻的内容
    id: '',
    title: ''
  },
  systemMessageNum: 0, // 系统消息数
  shareInfos: {}, // 分享消息内容
  shareMessage: false,  // 是否在分享消息
  messageCounter: 0,
  IMWindowVisible: false,
  IMDialogVisible: false,
  labTipVisible: false, // 是否展示定制数字科创中心的提示
  IMRoleDialogVisible: false,
  messageTrigger: "",
  messageTriggerTitle: '',
  messageUnlimited: false,
  messageQuickchat: false,
  serverId: '',
  toMemberId: '',
  messageTriggerServerid: '',
  activeNav: '官网首页',
  showFooter: true,
  changeBgByScroll: true
}
const mutations = {
  SET_LEAVE_MESSAGE_VISIBLE:(state,bool=true)=>{
    // 设定留言窗口可见
    state.leaveMessageVisible = bool
  },
  SET_LEAVE_MESSAGE_CLICK_INFO:(state,info= {})=>{
    // 设置留言对象信息
    state.leaveMessageClickInfo = info
  },
  SET_LEAVE_MESSAGE_CLICK_ID:(state,id='')=>{
    // 设定留言对象ID
    state.leaveMessageClickId = id
  },
  SET_LEAVE_MESSAGE_UPDATE:(state)=>{
    state.leaveMessageUpdate = !state.leaveMessageUpdate
  },
  SET_PENDING:(state,bool=false)=>{
    // 修改状态
    state.messagePending = bool
  },
  SET_OPEN_SERVICE_WINDOW:(state)=>{  // 打开在线客服窗口
    state.openServiceWindow = !state.openServiceWindow
  },
  SET_CLOSE_SERVICE_WINDOW:(state)=>{
    // 强制关闭客服窗口
    state.closeServiceWindow = !state.closeServiceWindow
  },
  SET_SERVICE_WINDOW:(state,bool)=>{
    state.showServiceWindow = bool
  },
  SET_OTHER_INFO:(state,info)=>{ // 设置其他内容
    state.otherInfo = info
  },
  SET_DEMAND_INFO:(state,info)=>{ // 设置承接需求
    state.demandInfo = info
  },
  SET_SYSTEM_NUM:(state,num)=>{
    state.systemMessageNum = num
  },
  SET_SHARE_INFOS:(state,infos)=>{
    state.shareInfos = infos
  },
  SET_SHARE_MESSAGE: (state,type)=>{
    state.shareMessage = type
  },
  SET_MESSAGE_COUNTER: (state, counter) => {
    state.messageCounter = counter
  },
  SET_ACTIVENAV: (state, nav) => {
    state.activeNav = nav
  },
  SET_FOOTER: (state, show) => {
    state.showFooter = show
  },
  SET_BG: (state, show) => {
    state.changeBgByScroll = show
  },
  SET_FIRST_WINDOW_VISIBLE: (state,bool)=>{
    // 开关初次创建身份弹窗
    state.firstWindowVisible = bool
  },
  SET_CREATE_WINDOW_VISIBLE: (state,bool)=>{
    // 开关创建身份弹窗
    state.createWindowVisible = bool
  },
  SET_IM_WINDOW_VISIBLE: (state, IMWindowVisible) => {
    state.IMWindowVisible = IMWindowVisible
  },
  SET_IM_WINDOW_DIALOG: (state, visible) => {
    state.IMDialogVisible = visible
  },
  SET_LAB_TIP_VISIBLE: (state, visible) => {
    state.labTipVisible = visible
  },
  SET_IM_WINDOW_ROLE_DIALOG: (state, visible) => {
    state.IMRoleDialogVisible = visible
  },
  ADD_COUNTER: (state) => {
    state.messageCounter += 1
  },
  POLL_MESSAGE_TRIGGER: (state, triggerId) => {
    state.messageTrigger = ""
    state.messageTrigger = triggerId
  },
  POLL_MESSAGE_TRIGGER_TITLE: (state, title) => {
    state.messageTriggerTitle = ""
    state.messageTriggerTitle = title
  },
  POLL_MESSAGE_UNLIMITED: (state, bool)=>{
    state.messageUnlimited = bool
  },
  POLL_MESSAGE_QUICKCHAT: (state, bool) => {
    state.messageQuickchat = ''
    state.messageQuickchat = bool
  },
  SET_SERVER_ID: (state, id) => {
    state.serverId = id
  },
  SET_TOMEMBERID: (state, id) => {
    state.toMemberId = id
  },
  POLL_MESSAGE_TRIGGER_SERVERID: (state, serverId) => {
    state.messageTriggerServerid = ""
    state.messageTriggerServerid = serverId
  }
}

const actions = {
  updateLeaveMessage({commit}){
    // 通知更新留言列表
    commit('SET_LEAVE_MESSAGE_UPDATE')
  },
  setLeaveMessageInfos({commit},param){
    // 设定留言窗口
    if(!param) return
    commit('SET_LEAVE_MESSAGE_VISIBLE',param.show)
    commit('SET_LEAVE_MESSAGE_CLICK_ID',param.id)
    commit('SET_LEAVE_MESSAGE_CLICK_INFO',param.info)
  },
  startQuickChat({commit}) {
    commit('SET_IM_WINDOW_ROLE_DIALOG', true)
  },
  hideRoleDialog({commit}) {
    commit('SET_IM_WINDOW_ROLE_DIALOG', false)
  },
  serviceCommunication({commit}){
    // 和小科聊天
    let id = getLocalUserId()
    const endPending = ()=>{
      pending = false
      commit('SET_PENDING',false)
    }
    if(id && !pending){
      pending = true
      commit('SET_PENDING',true)
      everydayGreet(id).then(()=>{
        // 获取客服每日交流
        endPending()
        commit('SET_IM_WINDOW_VISIBLE',true)
        commit('POLL_MESSAGE_TRIGGER','')
        setTimeout(()=>{
          commit('POLL_MESSAGE_TRIGGER',serviceId)
        },1000)
      }).catch((err)=>{
        console.error('获取客服每日信息错误:',err);
        endPending()
      })
    }
  },
  kqdCommunication({commit}){
    // 和小科聊天
    commit('SET_IM_WINDOW_VISIBLE',true)
    commit('POLL_MESSAGE_TRIGGER','')
    setTimeout(()=>{
      commit('POLL_MESSAGE_TRIGGER',kqdId)
    },1000)
  },
  startCommunicationNew({commit},id){
    // 开始聊天
    commit('SET_IM_WINDOW_VISIBLE',true)
    commit('POLL_MESSAGE_TRIGGER',id)
  },
  async startCommunication({commit}, param) {
    console.log('start communication:',param)
    // id-要添加的好友会员id      serverId-一键沟通时必传
    commit('SET_IM_WINDOW_VISIBLE', true)
    // 虽然POLL_MESSAGE_TRIGGER里是先将id设置为空再赋值，但是不会被watch捕捉到
    if(state.messageTrigger === param.id){    // 如果与现在的 messageTrigger 相同则异步设定为空
      await commit('POLL_MESSAGE_TRIGGER', '')
    }
    commit('POLL_MESSAGE_TRIGGER', param.id)
    // let { isVip } = JSON.parse(localStorage.getItem('userInfo'))
    // let { level, organizationVipEndTime, organizationType } = getUserInfo()
    if (param.unlimited) { //对于活动页，不需要会员也可以聊天
      commit('POLL_MESSAGE_UNLIMITED', true)
    }
    if (param.flag === false) { // 所有角色的人都可以进入聊天
      // id-要添加的好友会员id
      // serverId-一键沟通时必传
      commit('POLL_MESSAGE_TRIGGER_TITLE', param.title)
      commit('POLL_MESSAGE_TRIGGER_SERVERID', param.serverId)
      commit('SET_IM_WINDOW_VISIBLE', true)
      commit('POLL_MESSAGE_QUICKCHAT', param.quickChat)
    // } else if (!isVip) {  // 非VIP可以进行承接需求的操作，而不能在线联系 以 serverId 进行判断 !isVip && !param.serverId
      // commit('SET_IM_WINDOW_DIALOG', true)
      // commit('SET_IM_WINDOW_VISIBLE', false)
    } else {
      commit('SET_IM_WINDOW_VISIBLE', true)
      commit('POLL_MESSAGE_TRIGGER_SERVERID', param.serverId)
      commit('POLL_MESSAGE_TRIGGER_TITLE', param.title)
      commit('POLL_MESSAGE_TRIGGER', param.id)
      commit('POLL_MESSAGE_QUICKCHAT', param.quickChat)
    }
  },
  hideDialog({commit}) {
    commit('SET_IM_WINDOW_DIALOG', false)
  },
  changeMessageCount({commit}, num) {
    commit('SET_MESSAGE_COUNTER', num)
    localStorage.setItem('messageCounter', num)
  },
  setSystemMessageNum({commit},num){  // 设置系统消息数
    commit('SET_SYSTEM_NUM',num)
  },
  setShareInfos({commit},infos){  // 设置分享的内容
    commit('SET_SHARE_INFOS',infos)
  },
  setShareMessage({commit},type){ // 设置分享消息
    commit('SET_SHARE_MESSAGE',type)
  },
  showIMWindow({commit}) {
    // 1.7无需Vip都可以打开聊天界面
    // let { isVip } = JSON.parse(localStorage.getItem('userInfo'))
    // if (!isVip) {
    //   commit('SET_IM_WINDOW_DIALOG', true)
    //   return
    // }
    commit('SET_IM_WINDOW_VISIBLE', false)
    setTimeout(()=>{
      commit('SET_IM_WINDOW_VISIBLE', true)
    },200)
  },
  hideIMWindow({commit}) {
    commit('SET_IM_WINDOW_VISIBLE', false)
  },
  addCounter({commit}) {
    commit('ADD_COUNTER')
  },
  pollMessageTrigger({commit}, triggerId) {
    commit('POLL_MESSAGE_TRIGGER', triggerId)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
