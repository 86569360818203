// 创建路演相关数据

export default {
  namespaced: true,
  state: {
    // liveStatus: 2,
    liveListChange: false,
  },
  getters: {

  },
  mutations: {
    LIVE_LIST_CHANGE(state){  // 列表发生变化
      state.liveListChange = !state.liveListChange
    },
  },
  actions: {

  }
}