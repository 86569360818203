<template>
  <div class="menu">
    <el-menu
      class="el-menu-vertical-demo"
      @select="handleSelect">
      <el-menu-item index="0">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-0.png" style="width: 14px;height: 14px;"/>
        </div>
        <span class="menu-title">首页</span>
      </el-menu-item>
      <el-menu-item index="1">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-1.png" style="width: 14px;height: 14px;"/>
        </div>
        <span class="menu-title">团队管理</span>
      </el-menu-item>
      <el-menu-item index="2">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-2.png" style="width: 16px;height: 14px;"/>
        </div>
        <span class="menu-title">专家管理</span>
      </el-menu-item>
      <el-menu-item index="3">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-3.png" style="width: 14px;height: 16px;"/>
        </div>
        <span class="menu-title">订阅中心</span>
      </el-menu-item>
      <el-menu-item index="8">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-3.png" style="width: 14px;height: 16px;"/>
        </div>
        <span class="menu-title">需求管理</span>
      </el-menu-item>
      <el-menu-item index="4">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-4.png" style="width: 14px;height: 16px;"/>
        </div>
        <span class="menu-title">找专家</span>
      </el-menu-item>
      <el-menu-item index="5">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-5.png" style="width: 14px;height: 16px;"/>
        </div>
        <span class="menu-title">找项目</span>
      </el-menu-item>
      <el-menu-item index="6">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-6.png" style="width: 14px;height: 16px;"/>
        </div>
        <span class="menu-title">科研动态</span>
      </el-menu-item>
      <el-menu-item index="7">
        <div class="icon">
          <img src="@/assets/images/Tan/menu/icon-7.png" style="width: 14px;height: 16px;"/>
        </div>
        <span class="menu-title">AI科创助手</span>
      </el-menu-item>
    </el-menu>
    <div class="bottom-info">
      <div class="company-info">
        <div class="avatar">
          <MyAvatar :src="tjCompanyInfo?.logo || ''"
            :id="getCompanyAvatar()"
            :word="tjCompanyInfo?.companyName ? tjCompanyInfo?.companyName.charAt(0) : ''" :small="true">
          </MyAvatar>
        </div>
        <div class="right-info">
          <p class="name text-ol-ell">{{ tjCompanyInfo?.companyName || '-' }}</p>
          <p class="name">当前版本：{{ version }}</p>
        </div>
      </div>
      <div class="bottom">
        <div @click="update" class="update">
          <p>升级版本</p>
          <img src="@/assets/images/Tan/order/update.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyAvatar from './MyAvatar.vue'
import { setTanVipPop } from '@/utils/tool';
import { getTanLocalInfo,getTanId } from "@/utils/Tan/login";
import { getTanCompanyInfo } from "@/api/Tan/company";
import { mapGetters } from "vuex";
import { getTanBuyVipInfoList } from "@/api/Tan/vip";

export default{
  components:{
    MyAvatar,
  },
  data(){
    return{
      vipInfo: [],
      tjCompanyInfo: {},
      loading: false,
    }
  },
  computed:{
    version(){
      if(this.vipInfo?.length === 0) return '免费版'
      const flag1 = this.vipInfo.find(item=>item.vipType === 13 && item.useStatus)
      const flag2 = this.vipInfo.find(item=>item.vipType === 12 && item.useStatus)
      const flag3 = this.vipInfo.find(item=>item.vipType === 14 && item.useStatus)
      if(flag1){
        return '旗舰版'
      }
      else if(flag2){
        return '企业版'
      }
      else if(flag3){
        return '创新赛道'
      }
      else return '免费版'
    },
    ...mapGetters([
      "TanVipPopShow",
      "TanCompanyEditPopShow",
    ]),
  },
  watch:{
    TanVipPopShow(){
      this.getVipInfo()
    },
    $route(){
      this.getVipInfo()
    },
    TanCompanyEditPopShow(val){
      if(!val){
        this.getCompanyInfo()
      }
    }
  },
  mounted(){
    this.getVipInfo()
    this.getCompanyInfo()
  },
  methods:{
    getVipInfo(){
      getTanBuyVipInfoList().then(res=>{
        this.vipInfo = res.data
        this.$store.dispatch('Tan/setVipInfo',this.vipInfo)
      })
    },
    getCompanyAvatar(){
      if(!this.tjCompanyInfo?.tjCompanyId) return 0
      return Number(this.tjCompanyInfo?.tjCompanyId.replace(/[^0-9]/ig,""))
    },
    getCompanyInfo(){
      if(!getTanLocalInfo()?.tjCompanyInfo){
        this.loading = true
        getTanCompanyInfo(getTanId()).then(()=>{
          this.tjCompanyInfo = getTanLocalInfo()?.tjCompanyInfo
        }).finally(()=>{
          this.loading = false
        })
      }
      else{
        this.tjCompanyInfo = getTanLocalInfo()?.tjCompanyInfo
      }
    },
    update(){
      setTanVipPop(1)
    },
    handleSelect(val){
      const key = val + ''
      switch (key) {
        case '0':
          this.$router.push('/p-center')
          break;
        case '1':
          this.$router.push('/team-management')
          break;
        case '2':
          this.$router.push('/expert-management')
          break;
        case '3':
          this.$router.push('/resource-center')
          break;
        case '4':
          this.$router.push('/expert-search')
          break;
        case '5':
          this.$router.push('/project-search')
          break;
        case '6':
        this.$router.push('/dynamic-center')
          break;
        case '7':
          this.$router.push('/AI')
          break;
        case '8':
          this.$router.push('/demand-management')
          break;
        default:
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu{
  z-index: 2000;
  width: 242px;
  height: calc(100vh - 70px);
  position: fixed;
  box-sizing: border-box;
  padding-top: 20px;
  left: 0;
  top: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #242B48;
  border-radius: 0px 0px 10px 0px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  background-image: url('../../../assets/images/Tan/menu/menu-back.png');
  background-size: 100% 539px;
  background-position: left bottom;
  background-repeat: no-repeat;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .bottom-info{
    position: absolute;
    left: 0;
    bottom: 28px;
    width: 242px;
    .company-info{
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;
      align-items: flex-start;
      .avatar{
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 4px;
        margin-top: 1px;
      }
      .right-info{
        width: calc(100% - 32px);
        .name{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 12px;
          padding-bottom: 5px;
        }
      }
    }
    .bottom{
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 7px;
    }
    .update{
      display: flex;
      align-items: center;
      height: 22px;
      background: transparent;
      border-radius: 4px;
      border: 1px solid rgba($color: #ffffff, $alpha: 0.7);
      padding: 0 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      cursor: pointer;
      color: rgba($color: #ffffff, $alpha: 0.7);
      img{
        width: 14px;
        height: 12px;
        margin-top: 2px;
      }
    }
  }
}
.el-menu-vertical-demo{
  background: #242B48;
  border-right-color: #242B48;
  :deep(.el-menu-item){
    display: flex;
    align-items: center;
    &:hover{
      background: #242B48;
    }
    &:focus{
      background: #242B48;
    }
    .is-active{
      background: #242B48;
    }
    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
    .menu-title{
      font-size: 16px;
      color: #FFFFFF;
    }
  }
}
</style>