<template>
    <footer class="footer-new" :class="{'small-footer':smallScreen}">
        <div class="footer-container">
            <div class="footer-line-0 flex-cc">
                <div class="part flex-cc">
                    <img src="../assets/images/footer/footer-icon-0.png" style="width: 33px;height: 40px;margin-right: 20px;" />
                    <div class="words">
                        <p class="title">全天候品质服务</p>
                        <p>7x24小时专业工程师服务</p>
                    </div>
                </div>
                <div class="part flex-cc">
                    <img src="../assets/images/footer/footer-icon-1.png" style="width: 40px;height: 40px;margin-right: 18px;" />
                    <div class="words">
                        <p class="title">极速应答服务</p>
                        <p>秒级应答为业务保驾护航</p>
                    </div>
                </div>
                <div class="part flex-cc">
                    <img src="../assets/images/footer/footer-icon-2.png" style="width: 41px;height: 38px;margin-right: 17px;" />
                    <div class="words">
                        <p class="title">客户价值为先</p>
                        <p>从服务价值到创造价值</p>
                    </div>
                </div>
                <div class="part flex-cc">
                    <img src="../assets/images/footer/footer-icon-3.png" style="width: 36px;height: 41px;margin-right: 20px;" />
                    <div class="words">
                        <p class="title">全方位服务保障</p>
                        <p>打造专属的数字科创</p>
                    </div>
                </div>
            </div>
            <div class="footer-line-1 flex">
                <div class="part" :style="`margin-right: ${smallScreen ? 71 : 120}px;`">
                    <div class="title">
                        关于我们
                    </div>
                    <p class="mgb-14"><a @click="routerWindow($router,'/personal-center')">公司简介</a></p>
                    <p><a target="_blank" href="https://mobile.keqidao.com/carbonEducation">碳排放管理师培训</a></p>
                </div>
                <div class="part" :style="`margin-right: ${smallScreen ? 71 : 120}px;`">
                    <div class="title">
                        科研资讯
                    </div>
                    <p class="mgb-14"><a target="_blank" :href="aHrefLocation('/frontier')">科技前沿</a></p>
                    <p class="mgb-14"><a target="_blank" :href="aHrefLocation('/other-third?id=221461061107713')">产学研用</a></p>
                    <!-- <p><a target="_blank" :href="aHrefLocation('/other-third?id=214397828005889')">企业动态</a></p> -->
                </div>
                <div class="part" :style="`margin-right: ${smallScreen ? 71 : 116}px;`">
                    <div class="title">
                        平台保障
                    </div>
                    <p class="mgb-14"><a target="_blank" href="/yhxy">用户协议</a></p>
                    <p class="mgb-14"><a target="_blank" href="/yszc">隐私政策</a></p>
                    <p><a target="_blank" href="/nrsm">内容声明</a></p>
                </div>
                <div class="part" :style="`margin-right: ${smallScreen ? 53 : 135}px;`">
                    <div class="title">
                        联系我们
                    </div>
                    <p class="mgb-14">客服电话：400-6265-298</p>
                    <p class="mgb-14">企业邮箱：keqidaoyunying1@keqidao.com</p>
                    <p>公司地址：上海市松江区龙腾路1021号803</p>
                </div>
                <div class="part">
                    <div class="title">
                        扫描下载科企岛APP
                    </div>
                    <div class="flex">
                        <div class="code" style="margin-right: 20px;">
                            <img src="@/assets/images/footer/ios.png" />
                            <p>IOS</p>
                        </div>
                        <div class="code">
                            <img src="@/assets/images/footer/android.png" />
                            <p>Android</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-line-2">
            <p>
                copyrigt ©️ 2021科企岛(上海)网络技术有限公司 |
                <a class="protocol-name" target="_blank" href="https://www.keqidao.com/sitemap.xml">&nbsp;网站地图</a>
                &nbsp;|
                <a class="protocol-name" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" rel="nofollow">&nbsp;沪ICP备2021003328号-1 </a>
                &nbsp;|
                沪网公安备案 <a class="protocol-name" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo" rel="nofollow">&nbsp;3101150200888号&nbsp;</a>
            </p>
        </div>
    </footer>

</template>

<script>
import { routerWindow,aHrefLocation } from "@/utils/tool";
import { mapGetters } from "vuex";

export default {
    computed:{
        ...mapGetters([
            'smallScreen',
        ]),
    },
    methods: {
        aHrefLocation,
        routerWindow,
    }
}
</script>

<style scoped lang="scss">
.small-footer{
    .footer-container{
        width: 960px !important;
    }
    .footer-line-2{
        width: 960px !important;
    }
}

.footer-new{
    width: 100%;
    background-color: #F4F7FC;
    padding-bottom: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer-container{
        width: 1200px;
        .footer-line-0{
            padding: 44px 0 40px;
            border-bottom: 1px solid #DDE2E9;
            justify-content: space-between;
            width: 100%;
            padding-right: 28px;
            box-sizing: border-box;
            .part{
                .words{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 14px;
                }
                .title{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #111111;
                    line-height: 16px;
                    margin-bottom: 16px;
                }
            }
        }
        .footer-line-1{
            width: 100%;
            box-sizing: border-box;
            padding: 60px 0 80px;
            .part{
                .code{
                    img{
                        width: 80px;
                        height: 80px;
                        background: #D8D8D8;
                        border-radius: 4px;
                        overflow: hidden;
                    }
                    p{
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 14px;
                        margin-top: 3px;
                        width: 80px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
                .title{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #111111;
                    line-height: 20px;
                    margin-bottom: 21px;
                }
                p{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    white-space: nowrap;
                }
                a{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    white-space: nowrap;
                }
                a:hover{
                    color: $--color-theme !important;
                }
                a:visited{
                    color: #333333;
                }
                .mgb-14{
                    margin-bottom: 14px;
                }
            }
        }
    }
    .footer-line-2{
        width: 100%;
        padding: 20px 0;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        border-top: 1px solid #e6e6e6;
        a{
            color: #333333;
        }
        a:hover{
            color: $--color-theme !important;
        }
        a:visited{
            color: #333333;
        }
    }
}
</style>
