const state = {
  items: []
}

const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = items
  },
  CLEAR_ITEMS: (state) => {
    state.items = []
  },
}

const actions = {
  setItems({commit}, items) {
    commit('SET_ITEMS', items)
  },
  clearItems({commit}) {
    commit('CLEAR_ITEMS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
