<template>
  <div class="shadow-box">
    <div class="wrap-box">
      <div class="header">
        {{ isEdit ? "修改路演" : "创建路演" }}
        <img
          src="../../assets/images/live/close-btn.png"
          class="close-btn"
          alt=""
          @click="$emit('closeCreateLiveWindow')"
        />
      </div>
      <div class="window-body">
        <div class="form-item">
          <div class="form-title-wrap">
            <div class="form-title">路演类型</div>
          </div>
          <div style="display: flex">
            <div
              class="public"
              :class="{ interactive: liveForm.type == 0 }"
              @click="changePublic(0)"
            >
              <div>公开型</div>
            </div>
            <div
              class="public"
              :class="{ interactive: liveForm.type == 1 }"
              @click="changePublic(1)"
            >
              <div>互动型</div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演标题</div>
          </div>
          <el-input
            v-model="liveForm.name"
            placeholder="请输入路演标题"
            :maxlength="50"
          ></el-input>
        </div>
        <!-- 路演密码 -->
        <div class="form-item" v-if="!hasPwd">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演密码</div>
          </div>
          <el-checkbox v-model="hasPassword">开启路演密码</el-checkbox>
        </div>
        <div class="form-item" v-if="hasPassword">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演密码</div>
          </div>
          <el-input
            type="number"
            v-model="liveForm.password"
            placeholder="请输入路演密码"
            :maxlength="6"
            id="password"
            show-password
            @input="handelNumber"
          >
          </el-input>
          <!-- 密码按钮 canSeePassword -->
          <div class="password-btn" @click="changePasswordStatus">
            <img
              src="../../assets/images/live/password-can-see.png"
              alt=""
              class="password-img"
              v-if="canSeePassword"
            />
            <img
              src="../../assets/images/live/password-not-see.png"
              alt=""
              class="password-img-not"
              v-else
            />
          </div>
        </div>
        <!-- 修改路演密码 -->
        <div class="form-item" v-if="hasPwd">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演密码</div>
          </div>
          <el-checkbox v-model="hasPwd">开启路演密码</el-checkbox>
        </div>
        <div class="form-item" v-if="hasPwd">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">修改路演密码</div>
          </div>
          <el-input
            v-model="liveForm.password"
            placeholder="请输入路演密码"
            :maxlength="6"
            id="password"
            show-password
            @input="handelNumber"
          >
          </el-input>
          <!-- 密码按钮 canSeePassword -->
          <div class="password-btn" @click="changePasswordStatus">
            <img
              src="../../assets/images/live/password-can-see.png"
              alt=""
              class="password-img"
              v-if="canSeePassword"
            />
            <img
              src="../../assets/images/live/password-not-see.png"
              alt=""
              class="password-img-not"
              v-else
            />
          </div>
        </div>
        <!-- 邀请 -->
        <div v-if="liveForm.type == 1">
          <div class="invitation" @click="changeInvitation">
            <img src="../../assets/images/create.png" alt="" />
            <div>邀请</div>
          </div>
          <div class="invitation-dialog" v-if="invitationdialog">
            <div class="wrap-dialog">
              <div class="invitation-header">
                <el-input placeholder="搜索联系人" v-model.trim="searchName" @keydown.enter="searchMember()">
                  <template #append>
                    <el-button @click="searchMember"
                      ><i class="el-icon-search"></i
                    ></el-button>
                  </template>
                </el-input>
              </div>
              <ul class="invitation-body">
                <li v-for="(item, index) in searchMemberList" :key="index">
                  <div class="l">
                    <img :src="staticPath + item.headImage" />
                    <span class="name">{{ item.name }}</span>
                  </div>
                  <div
                    class="r"
                    v-if="item.invited"
                    @click="cancelAddMember(item)"
                  >
                    <span style="color: #a0a0a0">取消邀请</span>
                  </div>
                  <div class="r" v-else @click="addMember(item)">
                    <span>+邀请</span>
                  </div>
                </li>
              </ul>
              <div class="footer">
                <el-button type="primary" plain @click="handleCloseSearchDialog"
                  >确定</el-button
                >
              </div>
            </div>
          </div>
          <!-- 邀请列表 -->
          <div v-if="invitationdialog==false">
            <div
              class="invitation-list"
              v-for="(item, index) in invitationMemberIdList"
              :key="index"
            >
              <div class="left">
                <img :src="staticPath + item.headImage" alt="" />
                <div>{{ item.name }}</div>
              </div>
              <div class="invitation-btn" @click="cancelAddMember(item)">取消邀请</div>
            </div>
          </div>
        </div>

        <div class="form-item">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演时间</div>
          </div>
          <el-date-picker
            v-model="liveForm.planStartTime"
            type="datetime"
            placeholder="请选择路演时间"
            style="width: 100%"
          ></el-date-picker>
        </div>
        <div class="form-item">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演封面</div>
          </div>
          <el-upload
            style="display: flex; justify-content: flex-start"
            ref="upload"
            action="#"
            list-type="picture-card"
            :limit="1"
            :accept="'image/*'"
            :on-exceed="handleExceed"
            :http-request="handleUpload"
            :on-preview="handlePictureCardPreview"
            :on-change="loadJsonFromFile"
            :on-remove="handleRemove"
            :file-list="videoCover"
          >
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
            <template #file="{ file }">
              <!-- 修改加载排除 -->
              <el-progress
                v-if="file.percentage != 100 && videoCover.length === 0"
                type="circle"
                :percentage="file.percentage"
              ></el-progress>
              <div
                v-if="file.percentage == 100"
                style="position: relative; width: 100%; height: 100%"
              >
                <img
                  v-if="file.raw.type[0] == 'i'"
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <div @click="deleteThumb(file)" class="icon-delete">
                  <i
                    class="el-icon-delete"
                    style="fontsize: 16px; color: #fff"
                  ></i>
                </div>
                <div @click="handlePictureCardPreview(file)" class="icon-big">
                  <i
                    class="el-icon-circle-plus-outline"
                    style="fontsize: 16px; color: #fff"
                  ></i>
                </div>
              </div>
            </template>
          </el-upload>
          <el-dialog v-model="previewDialog" :append-to-body="true">
            <img
              style="width: 100%"
              fit="contain"
              :src="dialogImageUrl"
              alt=""
            />
          </el-dialog>
        </div>
        <div class="form-item">
          <div class="form-title-wrap">
            <!-- <div class="form-icon"></div> -->
            <div class="form-title">路演简介</div>
          </div>
          <V5wangEditorLive :placeHolder="'请输入路演介绍'" :value="liveForm.description" @updateContent="updateContent" :scroll="false" :live="true"></V5wangEditorLive>
        </div>
        <div class="form-btn">
          <el-button
            class="cancel"
            type="primary"
            @click="$emit('closeCreateLiveWindow')"
            >取消</el-button
          >
          <el-button class="create" type="primary" @click="submit">{{
            isEdit ? "修改" : "创建"
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { staticPath } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { bucketMap } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { memberSearch } from "@/api/user";
import { createforward, editLive, getLiveDetails } from "@/api/live";
import moment from "moment";
import {
  uploadTypeImage,
  uploadTypeVideo,
  cosByUploadType,
} from "@/api/upload";
import { mapGetters } from "vuex";
import V5wangEditorLive from '../../components/rich-text/V5wangEditorLive.vue';
import { stopMove,Move } from "@/utils/tool";
export default {
  components: { V5wangEditorLive },
  emits: ['closeCreateLiveWindow'],
  props: {
    editData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["imWindowVisible"]),
    liveTitle() {
      return this.liveForm.name;
    },
  },
  beforeUnmount(){
    Move()
  },
  created(){
    stopMove()
  },
  watch: {
    // hasPassword(val){
    //   if(!val){
    //     this.canSeePassword = false
    //     this.form.password = ''
    //   }
    // },
    liveTitle(val) {
      if (val.length === 50) {
        ElMessage.warning("活动名称最多50个字");
      }
    },
  },
  data() {
    return {
      canSeePassword: false, // 能否看见密码
      hasPassword: false, //是否有路演密码
      staticPath, // 固定前缀
      isEdit: false, // 是否修改
      liveId: "", // 修改的路演id
      liveForm: {
        description: "", //简介
        type: 0, //是否公开类型 0公开 1互动
      },
      invitationMemberIdList: [], //邀请id
      previewDialog: false,
      dialogImageUrl: "",
      uploadimgs: [],
      videoCover: [], // 封面的保存
      hasPwd: false,
      invitationdialog: false, // 弹层
      searchName: "",
      searchMemberList: [], // 邀请列表
      backImg:'', // 暂存图片
      isShowDialog:false, // 是否展开实名认证
    };
  },
  async mounted() {
    if (this.imWindowVisible) {
      // 聊天窗口已打开在则先关闭
      this.$store.dispatch("message/hideIMWindow");
    }
    if (this.editData) {
      const { name, planStartTime, description } = this.editData;
      if (name) {
        this.liveForm.name = name;
      }
      if (planStartTime) {
        this.liveForm.planStartTime = planStartTime;
      }
      if (description) {
        this.liveForm.description = description;
      }
     
      if (this.editData.id) {
        // 判断是否编辑
        this.isEdit = true;
        this.liveId = this.editData.id;
      }
      if (this.editData.thumb) {
        // 封面加载
        this.backImg = this.editData.thumb
        this.videoCover.push({
          name: "thumb",
          url: this.staticPath + this.editData.thumb,
        });
      }
    }
    if (this.isEdit) {
      this.getLiveInfo();
    }
  },
  methods: {
    // 是否公开类型
    changePublic(type) {
      this.liveForm.type = type;
    },
    getLiveInfo() {
      getLiveDetails(this.liveId).then((res) => {
        if (res.data.password) {
          //有密码显示修改密码
          this.hasPwd = true;
          this.liveForm.password = res.data.password;
        }
        this.liveForm.type =  res.data.type;
        if(res.data.invitationMemberList){
        this.invitationMemberIdList =  res.data.invitationMemberList;
        }
      });
    },
    handelNumber() {
      // 数字处理
      let pattern = new RegExp("^[0-9]$");
      this.liveForm.password = Array.from(this.liveForm.password)
        .filter((v) => pattern.test(v))
        .join("");
    },
    changePasswordStatus() {
      // 切换能否看到密码的状态
      let div = document.getElementById("password");
      if (this.canSeePassword) div.type = "password";
      else {
        div.type = "text";
      }
      this.canSeePassword = !this.canSeePassword;
    },
    // 打开弹层
    changeInvitation(){
      this.invitationdialog = true
      // this.invitationMemberIdList = []
    },
    // 搜索邀请
    searchMember() {
      if (this.searchName) {
        memberSearch(this.searchName).then((res) => {
          this.searchMemberList = res.data;
          this.searchMemberList.map((member)=>{
            this.invitationMemberIdList.find((item)=>{  // 重复匹配
              if(item.id === member.id){
                member.invited = true
                return true
              }
            })
            return member
          })
        });
      } else {
        ElMessage.warning("请输入要查找的联系人");
      }
    },
    // 邀请
    addMember(item) {
      this.searchMemberList.forEach((member) => {
        if (member.id == item.id) {
          member.invited = true;
        }
      });
      this.invitationMemberIdList.push(item);
    },
    //取消
    cancelAddMember(item) {
      this.searchMemberList.forEach((member) => {
        if (member.id == item.id) {
          member.invited = false;
        }
      });
      this.invitationMemberIdList.forEach((member, index) => {
        if (member.id == item.id) {
          this.invitationMemberIdList.splice(index, 1);
        }
      });
    },
    // 关闭弹层
    handleCloseSearchDialog() {
      this.invitationdialog = false;
      this.searchMemberList = []  // 清空列表和搜索关键词
      this.searchName = ''
    },

    handleRemove(file) {
      if (file.url.startsWith(this.staticPath)) {
        // 判断是否是加载出的图片
        this.videoCover.pop();
        return;
      }
      const files = this.$refs["upload"].uploadFiles;
      this.$refs["upload"].uploadFiles = files.filter((item) => item !== file);
      if (this.liveForm.thumb.length > 0) {
        this.liveForm.thumb = "";
      }
    },
    async loadJsonFromFile(file, fileList) {
      this.uploadimgs = fileList;
    },
    handleExceed() {
      //文件超出个数限制时的钩子
      ElMessage.warning("最多只能上传1张封面～");
    },
    async handleUpload(val) {
      var result = await this.directUpload(val.file, uploadTypeImage, true);
      this.liveForm.thumb = result.Location.slice(result.Location.indexOf("/"));
    },
    directUpload(file, uploadType, thumb = false) {
      var self = this;
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo),
      };
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video;
      let bucket =
        uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video;
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket /* 必须 */,
            Region: "ap-shanghai" /* 存储桶所在地域，必须字段 */,
            Key: randomFilename() /* 必须 */,
            StorageClass: "STANDARD",
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              if (thumb) {
                // 带封面的上传的组件
                self.$refs["upload"].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100);
                    if (item.percentage === 100) self.btnDisabled = false;
                    else self.btnDisabled = true;
                  }
                });
              } else {
                self.$refs["upload1"].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100);
                    if (item.percentage === 100) self.btnDisabled = false;
                    else self.btnDisabled = true;
                  }
                });
              }
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
              console.error('upload media error:',err,err.code);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    handlePictureCardPreview(file) {
      this.previewDialog = true;
      this.backImg = file
      if (file.url.startsWith(this.staticPath)) {
        // 判断是否是加载出的图片
        this.dialogImageUrl = file.url;
        return;
      }
      if (this.liveForm.thumb) {
        // 如果草稿中有，直接获取
        this.dialogImageUrl = this.staticPath + this.liveForm.thumb;
        return;
      }
      this.dialogImageUrl = URL.createObjectURL(file.raw);
    },
    deleteThumb(file) {
      let arr = this.$refs["upload"].uploadFiles;
      let index = arr.indexOf(file);
      this.liveForm.thumb = "";
      this.$refs["upload"].uploadFiles.splice(index, 1);
    },
    submit() {
      //提交表单
          const { name, planStartTime, password } = this.liveForm;
          if (this.hasPassword && !password) {
            ElMessage.warning("请输入路演密码");
            return;
          }
          if (!name) {
            ElMessage.warning("请输入路演标题");
            return;
          }
          if (!planStartTime) {
            ElMessage.warning("请输入路演时间");
            return;
          }
          if (password && password.length < 4) {
            ElMessage.warning("密码长度在4-6位数");
            return;
          }
          if (password && password.length > 6) {
            ElMessage.warning("密码长度在4-6位数");
            return;
          }
          //互动类型邀请人数只能8个最多
          if(this.invitationMemberIdList.length >= 9 && this.liveForm.type == 1 ){
            ElMessage.warning("参与人最多邀请八位");
            return
          }
          // if (!thumb){ // 封面不是必传
          //   ElMessage.warning('请输入路演封面图')
          //   return
          // }
          this.liveForm.invitationMemberIdList = []
          this.invitationMemberIdList.forEach(item=>{
              this.liveForm.invitationMemberIdList.push(item.id).toString()
          })
          this.liveForm.planStartTime = moment(planStartTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          if(this.videoCover.length>0&&this.backImg!=''){
            this.liveForm.thumb = this.backImg
          }
          if (this.isEdit) {
            if(!this.hasPwd && !this.hasPassword){ // 如果没有密码，则清空
              this.liveForm.password = ''
            }
            editLive(this.liveForm, this.liveId).then(() => {
              ElMessage.success("修改成功");
              this.$store.commit("live/LIVE_LIST_CHANGE"); // 成功后刷新列表
              setTimeout(() => {
                this.$emit("closeCreateLiveWindow");
              }, 1000);
            });
          } else {
            if(!this.hasPwd && !this.hasPassword){ // 如果没有密码，则清空
              this.liveForm.password = ''
            }
            createforward(this.liveForm).then(() => {
              ElMessage.success("创建成功");
              this.$store.commit("live/LIVE_LIST_CHANGE"); // 成功后刷新列表
              setTimeout(() => {
                this.$emit("closeCreateLiveWindow");
              }, 1000);
            });
          }
    },
    updateContent(val) {
      this.liveForm.description = val;
      // this.liveForm.summary = summary
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-icon-close-tip {
  display: none !important;
}
.shadow-box {
  background: rgba(0, 0, 0, 0.6);
  width: calc(100vw + 17px);
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 2002;
  display: flex;
  justify-content: center;
  .wrap-box {
    width: 1000px;
    margin-top: 50px;
    margin-bottom: 10px;
    border-radius: 16px;
    background: white;
    // overflow: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    position: relative;
    height: 90%;
    .header {
      position: relative;
      top: -2px;
      left: 50%;
      margin-left: -500px;
      width: 1000px;
      background: #e9f0fe;
      color: #101010;
      height: 88px;
      line-height: 88px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #101010;
      text-align: center;
      z-index: 999;
      .close-btn {
        position: absolute;
        right: 40px;
        top: 50%;
        margin-top: -12.5px;
        cursor: pointer;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
    .window-body {
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 0px;
      padding: 30px 130px;
      margin-top: 88px;
      height: calc(100% - 163px);
      overflow-y: scroll;
      .form-item {
        margin-bottom: 30px;
        position: relative;
        .password-btn {
          position: absolute;
          right: 10px;
          top: 53px;
          cursor: pointer;
          .password-img {
            width: 20px;
            height: 20px;
          }
          .password-img-not {
            margin-top: -1px;
            width: 20px;
            height: 20px;
          }
        }
        .form-title-wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 20px;
          .form-icon {
            width: 4px;
            height: 20px;
            background-color: $--color-theme;
            border-radius: 2px;
          }
          .form-title {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #666;
            position: relative;
            // padding-left: 10px;
            line-height: 24px;
          }
        }
        .public {
          border: 1px solid #f3f3f3;
          border-radius: 16px;
          height: 29px;
          width: 96px;
          text-align: center;
          cursor: pointer;
          font-family: Source Han Sans SC;
          background: #f3f3f3;
          color: #8a919f;
          font-weight: 400;
          margin-right: 10px;
          line-height: 29px;
          // img{
          //   width: 40px;
          //   height: 40px;
          //   margin-top:10px
          // }
        }
        .interactive {
          border: 1px solid $--color-theme;
          height: 29px;
          width: 96px;
          text-align: center;
          cursor: pointer;
          border-radius: 16px;
          font-family: Source Han Sans SC;
          background: $--color-theme;
          font-weight: 500;
          color: #ffffff;
          margin-right: 15px;
          line-height: 29px;
        }
        .form-type-select {
          span {
            color: #666;
            font-size: 20px;
            background: #f7f8f9;
            width: 119px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 4px;
            margin-right: 24px;
            display: inline-block;
            cursor: pointer;
          }
          .active {
            background: linear-gradient(
              180deg,
              $--color-theme,
              $--color-theme-dark
            );
            color: #fff;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          margin-top: 10px;
          li {
            list-style-type: none;
            background: #f3f3f3;
            width: 100%;
            height: 58px;
            border-bottom: 1px solid #bfbfbf;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
            &:first-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
            &:last-child {
              border: none;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            span {
              display: inline-block;
              color: #757575;
              font-size: 18px;
            }
          }
        }
      }
      .invitation {
        // border: 1px dashed #FCAD6B;
        display: flex;
        width: 100%;
        height: 40px;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        margin-bottom: 10px;
        background: rgb(50, 123, 240, 0.1);
        color: #2759dd;
        font-weight: 400;
        cursor: pointer;
        border-radius: 2px;
        justify-content: center;
        img {
          width: 16px;
          height: 16px;
          margin-top: 12px;
          margin-right: 12px;
        }
      }
      .invitation-dialog {
        position: relative;
        top: 12px;
        left: 27%;
        right: 0;
        bottom: 0;
        z-index: 1001;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 9px 0px rgba(213, 216, 223, 0.36);
        width: 356px;
        height: 424px;
        .wrap-dialog {
          // position: absolute;
          // top: 100px;
          .invitation-header {
            color: #fff;
            background: rgb(50, 123, 240,.2);
            text-align: center;
            font-size: 22px;
            height: 52px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            box-sizing: border-box;
            padding: 7px;
          }
          .invitation-body {
            box-sizing: border-box;
            background: #fff;
            margin: 0;
            padding: 0;
            overflow-y: auto;
            min-height: 340px;
            max-height: 426px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              // padding: 12px 37px 12px 46px;
              border-bottom: 1px solid #bfbfbf;
              .l {
                display: flex;
                align-items: center;
                img {
                  display: inline-block;
                  width: 46px;
                  height: 46px;
                  border-radius: 50%;
                  margin-right: 16px;
                }
                .name {
                  max-width: 300px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  color: #8f8c8c;
                  font-size: 24px;
                }
              }
              .r {
                span {
                  cursor: pointer;
                  color: $--color-theme;
                  font-size: 24px;
                }
              }
            }
            .shareLi {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 12px 10px 12px 46px;
              cursor: pointer;
              img {
                display: inline-block;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                margin-right: 16px;
              }
              .r {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .top {
                  color: #8f8c8c;
                  font-size: 18px;
                }
                .bot {
                  color: #aeadad;
                  font-size: 14px;
                }
              }
            }
          }
          .footer {
            background: #fff;
            width: 459px;
            padding: 20px 30px;
            box-sizing: border-box;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            display: flex;
            justify-content: center;
          }
        }
      }
      .invitation-list {
        display: flex;
        border: 1px solid #dcdfe6;
        height: 50px;
        line-height: 50px;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        border-radius: 8px;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
        }
        img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .invitation-btn {
          border: 1px solid #8991a0;
          color: #8991a0;
          border-radius: 25px;
          text-align: center;
          width: 80px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: 10px;
        }
        .invitation-btn:hover{
          color: $--color-theme;
          border: 1px solid $--color-theme;
        }
      }
      .form-btn {
        cursor: pointer;
        height: 50px;
        color: #fff;
        font-family: Source Han Sans SC;
        font-weight: 700;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .create {
          width: 154px;
          background: $--color-theme;
          border-radius: 26px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        .cancel {
          background: #8991a0;
          border-radius: 26px;
          width: 154px;
          box-sizing: border-box;
          border: none;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .wrap-box::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
::v-deep .el-input-group--append .el-input__inner {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}
::v-deep .el-input-group__append {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.icon-delete {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 3px 10px 0 10px;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}
.icon-big {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 3px 10px 0 10px;
  top: 0;
  right: 40px;
  position: absolute;
  cursor: pointer;
}
::v-deep .el-checkbox__label {
  font-size: 22px !important;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0e1b35;
}
::v-deep .el-checkbox {
  display: flex;
  align-items: center;
  background: #fefcfc;
  border-radius: 4px;
  padding: 13px 20px;
}
::v-deep .el-input__suffix {
  display: none;
}
</style>

