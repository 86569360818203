<template>
    <div class="iconChatBox" v-if="imRoleDialogVisible">
      <div class="img">
        <img src="@/assets/images/icon-chat2.png" />
        <div class="content">
          <h3>您尚未完善身份信息</h3>
          <p>请先完善身份信息，才能在线沟通</p>
          <div class="btns">
            <span @click="close">稍后在说</span>
            <span @click="selectRole">立即完善</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { tanVipJudge } from "@/utils/auth";

export default {
  computed: {
    ...mapGetters(['imRoleDialogVisible', 'isVip'])
  },
  methods: {
    close () {
      if (!this.isVip) {
        this.$store.dispatch("message/hideRoleDialog")
        return
      }
      this.$store.dispatch("message/startCommunication")
    },
    selectRole () {
      // this.$store.dispatch("popup/setPopup", {
      //   type: 11, // 创建身份弹窗
      //   parameter: {},
      // });
      this.close()
      tanVipJudge({
      })
    }
  }
}
</script>

<style scoped lang="scss">
.iconChatBox {
  width: 396px;
  height: 405px;
  z-index: 999;
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -200px;
  .img {
    width: 396px;
    height: 405px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .content {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 182px;
      h3 {
        color: #333;
        font-size: 22px;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
      }
      p {
        color: #666;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-bottom: 50px;
      }
      .btns {
        span {
          display: inline-block;
          width: 134px;
          height: 42px;
          line-height: 42px;
          border-radius: 22px;
          cursor: pointer;
          &:nth-child(1) {
            color: #999;
            border: 1px solid #999;
            margin-right: 28px;
          }
          &:nth-child(2) {
            color: #fff;
            background: linear-gradient(-90deg, #F77F1A 0%, #FCA643 100%);
          }
        }
        
      }
    }
  }
}
</style>

