import {post,get} from "@/utils/request";
import store from '@/store';
import { TanSite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";

const demandParamTypeArr = ['input','qidaoIndustry','qidaoAddress','qidaoDict','picker','selectMultiple']

// 1.10.13 获取我未查看过详情
export function getMyUnreadDemand(){
	const params = {
		"memberId": store.getters.id,
		"type": 1
	}
	return post('/unreadRecords/list',params)
}
// 1.10.13 删除我的需求未读记录
export function deleteMyUnreadDemand(demandId){
	const params = {
		"memberId": store.getters.id,
		"sourceId": demandId,
		"type": 1
	}
	return post('/unreadRecords/delete',params)
}

// #ifdef DEBUG type 0
// demandType = @"170517300576257";
// #else
// demandType = @"172015538274305";
// #endif


// 1.10.11 获取需求列表
export function getDemandList(industryId,typeId,keyword,limit,offset,type,iurChannelId = ''){
	const params = industryId ? {
		"demandType": typeId,
		"keyword": keyword,
		"industryId":	[...industryId],
		"limit": limit,
		"memberId": store.getters.id,
		"offset": offset,
		"type": type  		// 0 最新 1 推荐,示例值(1) false
	} : {
		"demandType": typeId,
		"keyword": keyword,
		"limit": limit,
		"memberId": store.getters.id,
		"offset": offset,
		"type": type  		// 0 最新 1 推荐,示例值(1) false
	}
	if(iurChannelId.length > 0) params.channelId = iurChannelId
	return post('/demand/list',params)
}
// 查询需求匹配专家
export function demandExpertLists(keyword,offset,limit){
	let params = {
		"keyword": keyword,
		"offset": offset,
		"limit": limit ? limit : 5,
	}
	return post('/demand/getExpertListByDemandId',params)
}

//查询列表复制接口复制文档复制地址
export function demandList(industryId,limit,memberId,offset,type){
	const params = {
		"industryId": [
			industryId
          ],
          "limit": limit,
          "memberId": memberId,
          "offset": offset,
          "type": type  		// 0 最新 1 推荐,示例值(1) false
	}
	return post('/demand/list',params)
}


// 查询服务中心消息数

export function demandSize(memberId){
	const params = {
		"memberId": memberId
	}
	return post('/demand/size',params)
}

// 检查消息是否存在
export function demandExist(demandId,memberId){
	const params = {
		"demandId": demandId,
		"memberId": memberId
	}
	return post('/demand/exist',params)
}


//查询详情复制接口复制文档复制地址
export function demandDetail(id){
	const params = {
		"id": id             // 服务需求ID,示例值(167295148752897)
	}
	return post('/demand/detail',params)
}


//查询一键沟通消息内容
export function checkCommunicate(demandId,toMemberId){

	const params = {
		"demandId": demandId,
		"fromMemberId": store.getters.id,
		"toMemberId": toMemberId
	}
	return post('/demand/communicate',params)
}

//
//查询一键沟通消息内容(动态)
export function checkDynamicCommunicate(demandId,toMemberId){
	const params = {
		"dynamicId": demandId,
		"fromMemberId": store.getters.id,
		"toMemberId": toMemberId
	}
	return post('/dynamic/communicate',params)
}


// 删除 服务需求（操作status字段关闭状态）复制接口复制文档复制地址
export function deleteDemand(demandId,memberId){   // 暂时不用
	const params = {
		"demandId": demandId,
		"memberId": memberId
	}
	return post('/demand/delete',params)
}


// 关闭 服务需求（操作status字段关闭状态）复制接口复制文档复制地址
export function closeDemand(demandId,memberId){
	const params = {
		"demandId": demandId,
		"memberId": memberId
	}
	return post('/demand/close',params)
}

// 获取组织机构下所有成员发布的需求复制接口复制文档复制地址
export function getOrganizationDemands(limit,memberId,offset,organizationId){
	const params = {
		"limit": limit,   // 10
		"memberId": memberId,
		"offset": offset, // 1
		"organizationId": organizationId
	}
	return post('/demand/organization',params)
}



// 获取我发布的需求复制接口复制文档复制地址  /demand/mine
// 返回的需求是targetId用户发布的需求
// targetId与memberId相等时展示未审核通过的和已关闭的需求
// targetId与memberId不相等时只返回审核通过未关闭的需求
export function getMyDemands(limit,offset,targetId){
	let id = store.getters.id
	if(TanSite) id = getTanId()
	const params = {
		"limit": limit,   // 10
		"memberId": id,
		"offset": offset, // 1
		"targetId": targetId
	}
	return post('/demand/mine', params)
}



//检查是否是最新版本:草稿验证 /demand/able
export function getDraftVersion(tpyeId,draftversion){
	const params = {
		"typeId": tpyeId,
		"version": draftversion
	}
	return post('/demand/able',params)
}


// 获取需求筛选列表
export function getIndustry(){
	return post('/demand/industry')
}


// 获取服务需求的发布类型
export function getposttype(memberId){
	const params = {
		"memberId": memberId ? memberId : ''
	}
	return post('/demand/type',params)
}

// 获取服务需求的发布模板
export function getposttemplate(memberId,tpyeId){
	const params = {
		"memberId": memberId,
		"typeId": tpyeId
	}
	return post('/demand/template', params)
}

// 发布服务需求
export function publishDemand(data){
  let listValLabel = []
	data.params.forEach((item)=>{
		if(demandParamTypeArr.includes(item.type) && listValLabel.length < 3){
			if((item.type === 'input' && item.key !== 'firTitle') || item.type === 'picker'){
				listValLabel.push({
					key: item.name,
					value: item.value
				})
			}
			else if(item.type === 'qidaoAddress'){
				listValLabel.push({
					key: item.name,
					value: JSON.parse(item.value).addressDetail
				})
			}
			else if(item.type === 'qidaoIndustry' || item.type === 'qidaoDict' || item.type === 'selectMultiple'){
				if(item.label){
					listValLabel.push({
						key: item.name,
						value: item.label
					})
				}
			}
		}
	})
	if(listValLabel.length > 0){
		data.listValLabel = JSON.stringify(listValLabel)
	}
	return post('/demand/publish', data)
}




// 草稿验证最新版本
export function getdraftversion(tpyeId,version){
	const params = {
		"typeId": tpyeId,
		"version": version
	}
	return post('/demand/able', params)
}



export function postdemand (data) { // post Demo
    return post('/server/insertServer', data)
}

export function deldemand (data) {
	return post('/server/deleteServer', data)
}

export function getprovince () {
	return get('/address/address/getAllProvince')
}

export function getcity(id) {
	const data= {
		provinceCode: id
	}
	return get('/address/address/getCityByProvinceCode', data)
}

export function getarea(id) {
	const data = {
		cityCode: id
	}
	return get('/address/address/getCityByCityCode', data)
}

export function getSelect(data) {
	return post('/config/getSelectByType', data)
}

export function getMyServer() {
	return post('/server/infoOrganizationServerContentPage',{
		"limit": 10,
		"memberId": store.getters.id,
		"offset": 1
	})
}

export function getServerDetail(id) {
	return get('/server/detail', {
		serverId: id
	})
}

export function demandCenter(industryId, page, pageSize) {
	return post('/server/infoOrganizationServerContentPage', {
		limit: pageSize,
		offset: page,
		memberId: store.getters.id,
		industryId
	})
}

export function demandSelectList(page, pageSize, industryId) {
	let obj = {
		limit: pageSize,
		offset: page,
		memberId: store.getters.id
	}
	if (industryId.length > 0) {
		obj.industryId = industryId
	}
	return post('/server/list', obj)
}

export function demandsPublished(sourceId, page, pageSize) {
	return post('/server/infoServerPage',{
		"limit": pageSize,
		"memberId": store.getters.id,
		"offset": page,
		"sourceId": sourceId
	})
}

//个人空间展示需求（组织下所有成员发布的需求）
export function demandsZoom(page, pageSize) {
	return post('/server/listServerMember',{
		"limit": pageSize,
		"memberId": store.getters.id,
		"offset": page
	})
}

// 查询一键沟通消息
export function findCommunicateMsg(toMemberId, serverId) {
	return post('/server/findCommunicateMsg',{
		"toMemberId": toMemberId,
    "fromMemberId": store.getters.id,
    "serverId": serverId
	})
}