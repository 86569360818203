<template>
  <header class="navbar" :style="`${backgroundColor ? 'background-color:' + backgroundColor + ';' : ''}`" v-if="!notShow">
    <div class="center">
      <div class="left">
        <img src="@/assets/images/G60/announce/logo.png" style="width: 81px;height: 44px;margin-right: 50px;"/>
        <a href="/" target="_blank" title="首页" class="a" :class="{'a-active': routePath === '/'}">首页</a>
        <a @click="scrollToSmooth('INT')" title="产品功能" class="a-1" :class="{'a-active': routePath === ''}">产品功能</a>
        <a @click="scrollToSmooth('AD')" title="产品优势与特点" class="a-1" :class="{'a-active': routePath === ''}">产品优势与特点</a>
        <a @click="scrollToSmooth('VERSION')" title="产品版本与定价" class="a-1" :class="{'a-active': routePath === ''}">产品版本与定价</a>
        <!-- <a href="/" title="客户案例" class="a" :class="{'a-active': routePath === '/'}">客户案例</a> -->
        <a @click="scrollToSmooth('US')" title="联系我们" class="a-1" :class="{'a-active': routePath === ''}">联系我们</a>
      </div>
      <div class="right">
        <button class="login-button" @click="showLoginPop" v-loading="infoLoading" v-if="!loginNavbarStatus">登录 | 注册</button>
        <div v-else class="login-part">
          <a :href="kqdPath" target="_blank" class="kqd-button">科企岛首页</a>
          <el-popover
            placement="bottom"
            :width="232"
            trigger="hover"
            :popper-class="'popper-class-user popper-class-transparent'"
            content="keqidao">
            <template #reference>
              <div class="flex-cc" v-if="!hasTan">
                <img v-if="userAvatar" class="avatar" :src="staticPath + userAvatar"/>
                <img v-else src="@/assets/images/avatar.jpg" class="avatar"/>
              </div>
              <div class="flex-cc avatar" v-else>
                <MyAvatar :src="TanInfo.img" :id="TanInfo.tjMemberId" :word="avatarName()"></MyAvatar>
              </div>
            </template>
            <div class="self-menu">
              <div class="user-info">
                <div v-if="!hasTan">
                  <img v-if="userAvatar" class="avatar" :src="staticPath + userAvatar" />
                  <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
                </div>
                <div v-else class="avatar">
                  <MyAvatar :src="TanInfo.img" :id="TanInfo.tjMemberId" :word="avatarName()"></MyAvatar>
                </div>
                <div class="infos">
                  <div class="user-name">{{ hasTan ? TanInfo.name : userName }}</div>
                  <p v-if="hasTan" class="text-ol-ell">
                    {{ TanInfo.tjCompanyName }}
                  </p>
                  <p class="text-ol-ell" v-else>您还未定制探角</p>
                </div>
              </div>
              <div class="tan-center flex-cc" v-if="hasTan" >
                <div class="tan-b" @click="toMyTan">
                  <p class="p-0">我的探角</p>
                  <!-- <p class="p-1 text-ol-ell">使用有效期至：<span>{{ viptime }}</span></p> -->
                  <p class="p-1 text-ol-ell">{{ TanInfo.tjCompanyName }}</p>
                  <div class="right">
                    <img src="@/assets/images/navbar/right-icon.png" />
                  </div>
                </div>
              </div>
              <div class="buttons">
                <div class="button" @click="commitLogout()">
                  <div class="flex-cc">
                    <img src="@/assets/images/navbar/logout_t.png" alt="" class="left-icon" />
                    <div>退出登录</div>
                  </div>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </header>
  <div class="pops" v-if="!notShow">
    <div class="success" v-if="showSuccess">
      <SuccessPop></SuccessPop>
    </div>
    <div class="table" v-if="showTable">
      <TablePop></TablePop>
    </div>
    <div class="vip-form" v-if="showVipForm">
      <VipFormPop></VipFormPop>
    </div>
  </div>
</template>

<script>
import { getTanLocalInfo,removeTanLocalInfo,setTanLocalInfo } from "@/utils/Tan/login";
import { mapGetters } from "vuex";
import { ElMessage, ElMessageBox } from 'element-plus';
import SuccessPop from "./SuccessPop.vue";
import TablePop from "./TablePop.vue";
import VipFormPop from "./VipFormPop.vue";
import { staticPath,TanWhitePage,kqdPath,TanNotShowNavbar } from "@/utils/consts";
import { routerWindow } from "../../../utils/tool";
import { memberDetails } from "@/api/user";
import { setCookie } from "@/utils/Cookies/index";
import { timeFromList } from "@/utils/time";
import { getTanMemberInfo } from "@/api/Tan/member";
import { getTanId } from "@/utils/Tan/login";
import MyAvatar from './MyAvatar.vue';


export default{
  components:{
    MyAvatar,
    SuccessPop,
    TablePop,
    VipFormPop,
},
  data(){
    return{
      TanInfo: {},
      infoLoading: false,
      kqdPath,
      loading: false,
      info: {},
      detailInfo: {},
      viptime: '',
      backgroundColor: '',
      staticPath,
      scrollInter: null,
    }
  },
  watch:{
    visibility(val){
      if(val) this.setInfo()
    },
    $route(){
      this.setInfo()
    },
    loginStatusChange(){
      if(this.loginStatusFrom === 'navbar' || this.loginStatusFrom === 'logout') return
      this.setInfo()  // 重新设置信息
    },
  },
  computed:{
    notShow(){
      return TanNotShowNavbar.includes(this.$route.path)
    },
    orgName(){
      return this.detailInfo?.organizationName || '-'
    },
    hasTan(){
      return this.TanInfo.tjCompanyId || ''
    },
    userName(){
      return this.info?.name || '-'
    },
    userAvatar(){
      return this.info?.headImage || ''
    },
    isLogin(){
      return this.$route.path === '/login'
    },
    showAchieve(){
      return this.achieveStatus.show
    },
    showDemand(){
      return this.demandStatus.show
    },
    showCor(){
      return this.corStatus.show
    },
    showAsk(){
      return this.askStatus.show
    },
    showSuccess(){
      return this.successStatus.show
    },
    showExpert(){
      return this.expertStatus.show
    },
    showQuestion(){
      return this.questionStatus.show
    },
    routePath(){
      return this.$route.path
    },
    loginNavbarStatus(){
      return this.info?.id
    },
    showVip(){
      return this.vipStatus.show
    },
    showTable(){
      return this.tableStatus.show
    },
    showVipForm(){
      return this.vipFormStatus.show
    },
    loginStatusChange(){
      return this.loginStatus.change
    },
    loginStatusFrom(){
      return this.loginStatus.from
    },
    ...mapGetters([
      "visibility",
      "loginStatus",
      "achieveStatus",
      "demandStatus",
      "corStatus",
      "askStatus",
      "successStatus",
      "expertStatus",
      "questionStatus",
      "vipStatus",
      "tableStatus",
      "vipFormStatus",
    ]),
  },
  mounted(){
    this.setInfo()
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods:{
    avatarName(){
      if(this.hasTan){
        return this.TanInfo.name ? this.TanInfo.name.charAt(0) : ''
      }
      else{
        return this.userName ? this.userName.charAt(0) : ''
      }
    },
    toMyTan(){
      routerWindow(this.$router,'/p-center',{})
    },
    showLoginPop(){
      if(this.loginNavbarStatus){
        // 已经登录
        this.infoLoading = true
        getTanMemberInfo(getTanId()).then((res)=>{
          if(res.data?.tjCompanyId){
            // 已创建探角且非分享状态
            this.$router.push('/p-center')
          }
          else{
            this.$router.push('/identity')
          }
        }).finally(()=>{
          this.infoLoading = false
        })
        return
      }
      this.$store.dispatch('Tan/setLoginShow',true)
    },
    scrollToSmooth(eId){
      if(!eId || this.scrollInter) return
      const dom = document.getElementById(eId)
      if(!dom) return
      let scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > dom.offsetTop){
         this.scrollInter = setInterval(()=>{
          scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if(scrollTop - 50 <= dom.offsetTop){
            dom.scrollIntoView()
            clearInterval(this.scrollInter)
            this.scrollInter = null
          }
          else{
            document.scrollingElement.scrollTop -= 50
          }
        },10)
      }
      else if(scrollTop < dom.offsetTop){
        this.scrollInter = setInterval(()=>{
          scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if(scrollTop + 50 >= dom.offsetTop){
            dom.scrollIntoView()
            clearInterval(this.scrollInter)
            this.scrollInter = null
          }
          else{
            document.scrollingElement.scrollTop += 50
          }
        },10)
      }
      // dom.scrollIntoView()
    },
    handleScroll () {
      const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 150){
        this.backgroundColor = ''
      }
      else{
        this.backgroundColor = `rgba(255,255,255,${scrollTop / 150})`
      }
    },
    toRoute(path,id){
      if(!path) return
      routerWindow(this.$router,path,{id})
    },
    toUserCenter(){
      routerWindow(this.$router,'/user',{})
    },
    toPublish(type){
      routerWindow(this.$router,'/publish-dynamic',{type})
    },
    setInfo(){
      if(this.notShow) return
      this.info = getTanLocalInfo()?.memberInfo || {}
      if(this.loginNavbarStatus){
        this.TanInfo = getTanLocalInfo()?.tjInfo || {}
        if(this.loading) return
        this.loading = true
        memberDetails(this.info.id).then(res=>{
          let newInfo = getTanLocalInfo()
          this.detailInfo = res.data
          this.viptime = timeFromList(
            res.data.organizationVipEndTime?.splice(0, 3)
          );
          newInfo.memberInfo.organizationType = this.detailInfo.organizationType
          newInfo.memberInfo.organizationId = this.detailInfo.organizationId
          newInfo.memberInfo.organizationTypeId = this.detailInfo.organizationTypeId
          newInfo.memberInfo.isVip = this.detailInfo.isVip
          setTanLocalInfo(newInfo)  // 将类型存入
          this.$store.dispatch('Tan/setLoginChange','navbar') // 通知获取状态
          this.info = getTanLocalInfo()?.memberInfo // 再次获取
        }).finally(()=>{
          this.loading = false
        })
      }
    },
    commitLogout(){
      ElMessageBox.confirm("是否确认退出登录?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        lockScroll: false,  // 防止页面振动
        type: "warning",
      }).then(() => {
        ElMessage.success('成功退出登录!')
        removeTanLocalInfo()
        setCookie('KqdInfo',{status: '0'})
        this.$store.dispatch('Tan/setLoginChange','logout')
        this.detailInfo = {}
        this.setInfo()
        let path = this.$route.path
        if(!TanWhitePage.includes(path)){
          this.$router.push({
            path: '/' // 返回首页
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.seize {
  width: 100%;
  height: 60px;
}
.navbar {
  height: 60px;
  width: 100%;
  min-width: 1200px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  .center{
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      .logo-0{
        width: 172px;
        height: 55px;
        margin-right: 8px;
      }
      .logo-1{
        width: 119px;
        height: 38px;
        margin-right: 34px;
      }
      .p-title{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        cursor: pointer;
        &:hover{
          color: $--color-theme;
        }
      }
      .a-1{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        cursor: pointer;
        &:hover{
          color: $--color-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .a{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        cursor: pointer;
        &:hover{
          color: $--color-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .a-active{
        color: $--color-theme !important;
        &:visited{
          color: $--color-theme;
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      .p{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 14px;
        cursor: pointer;
        &:hover{
          color: $--color-theme;
        }
      }
      .login-button{
        height: 36px;
        box-sizing: border-box;
        padding: 10px 15px;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #FB8529;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FB8529;
        line-height: 16px;
        margin-left: 10px;
      }
      .login-part{
        display: flex;
        align-items: center;
        height: 100%;
        .kqd-button{
          margin-right: 33px;
          height: 36px;
          box-sizing: border-box;
          padding: 10px 15px;
          background-color: transparent;
          border-radius: 4px;
          border: 1px solid #FB8529;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #FB8529;
          line-height: 16px;
          margin-left: 10px;
        }
        .avatar{
          width: 36px;
          height: 36px;
        }
      }
      .center{
        width: 101px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #111111;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 28px;
        cursor: pointer;
        margin-left: 10px;
        background-color: #ffffff;
        text-align: center;
        box-sizing: border-box;
        padding-left: 8px;
        &:visited{
          color: #111111;
        }
        &:hover{
          color: $--color-theme;
          border: 1px solid $--color-theme;
        }
      }
    }
  }
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}
.avatar-name{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 20px;
  max-width: 140px;
  margin-left: 6px;
  cursor: pointer;
  &:hover{
    color: $--color-theme;
  }
}
.self-menu {
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  .user-info {
    display: flex;
    width: 100%;
    padding: 25px 15px;
    align-items: center;
    background: #ffffff;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
    .infos{
      width: calc(100% - 42px);
      border-radius: 0 0 4px 4px;
      p{
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 16px;
        margin-top: 7px;
      }
    }
    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 7px;
      margin-left: 0;
    }
    .user-name {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #111111;
      line-height: 16px;
      width: 100%;
    }
  }
  .vip-services {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .vip {
      margin-top: 10px;
      width: 47%;
      height: 68px;
      padding: 5px 0;
      background: #f1f5f8;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .top {
          font-size: 18px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          .golden {
            width: 28px;
            height: 38px;
            margin-right: 5px;
          }
          .diamond {
            width: 38px;
            height: 38px;
            margin-right: 5px;
          }
          margin-right: 10px;
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
      .right {
        width: 46px;
        height: 32px;
        background: #feebd7;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #864d07;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
        margin-right: 10px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .full-width {
      width: 100%;
      .left {
        flex-direction: row !important;
        justify-content: flex-start;
        align-items: center;
      }
      .right {
        width: 56px;
        border-radius: 4px;
        margin-right: 10px;
        background: #dfe0e5;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
    }
  }
  .tan-center{
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 0 15px 15px;
    border-radius: 0 0 4px 4px;
    .tan-b{
      width: 100%;
      height: 71px;
      background-image: url('../../../assets/images/navbar/navbar-tan-c.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding: 15px 16px 13px;
      position: relative;
      cursor: pointer;
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        line-height: 20px;
        margin-bottom: 11px;
      }
      .p-1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 16px;
        width: 100%;
        span{
          color: #FB872D;
        }
      }
      .right{
        position: absolute;
        height: 12px;
        width: 12px;
        top: 50%;
        margin-top: -6px;
        right: 16px;
      }
    }
  }
  .buttons {
    margin-top: 1px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    background: #FFFFFF;
    padding-bottom: 5px;
    border-radius: 4px;
    .button:hover{
      background: #2282ff1b;
      color: $--color-theme;
      .right-icon{
        background-image: url('../../../assets/images/navbar/right-icon-hover.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .button {
      padding: 10px 20px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      cursor: pointer;
      .left-icon {
        margin-top: 2px;
        width: 16px;
        height: 16px;
        margin-right: 4px
      }
      .right-icon{
        background-image: url('../../../assets/images/navbar/right-icon.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      font-size: 14px;
      line-height: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #111111;
    }
  }
  .exit-login {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
    .exit {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8991a0;
      cursor: pointer;
    }
  }
}

.self-menu-item {
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.self-menu-item:hover {
  background: #ec8541;
  color: white;
}

.p-publish{
  width: 84px;
  height: 28px;
  background: $--color-theme;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}
.news{
  .news-type{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 20px;
    padding: 10px 0;
    cursor: pointer;
    &:hover{
      color: $--color-theme !important;
    }
    &:visited{
      color: #111111;
    }
  }
}
.cities{
  .city{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 20px;
    padding: 10px 0;
    cursor: pointer;
    &:hover{
      color: $--color-theme;
    }
  }
  .wait{
    position: relative;
    &::after{
      content: '即将上线';
      position: absolute;
      top: 2px;
      right: 5px;
      font-size: 12px;
      color: #ffffff;
      background-color: #ff5e52;
      border-radius: 8px 8px 8px 0;
      padding: 0 2px;
    }
  }
}
.entrance-line{
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  .p-0{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 16px;
    margin-bottom: 6px;
  }
  .p-tip{
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }
  .entrance-0{
    width: 100%;
    height: 57px;
    background-image: url('../../../assets/images/G60/Navbar/entrance-0.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 12px;
    cursor: pointer;
    margin-bottom: 7px;
  }
  .entrance-1{
    width: 100%;
    height: 57px;
    background-image: url('../../../assets/images/G60/Navbar/entrance-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 12px;
    cursor: pointer;
  }
}

</style>