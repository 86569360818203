export const environment = 'online'  // 环境判断 uat: 测试 online: 线上 tprod: 预发布
// export const baseURL = 'http://192.168.0.21:8000'      // 测试环境
export const baseURL = environment === 'uat' ? 'https://uapi.keqidao.com' : 'https://api.keqidao.com/'

export const TanBaseUrl = environment === 'uat' ? 'https://utanjiao.keqidao.com' : 'https://tanjiao.keqidao.com'
export const uploadUserPhone = 15551385182
export const chatLimit = 15 // 聊天记录单次获取上限
export const kqdId = environment === 'online' || environment === 'tprod' ? '256067906895873' : '278191697297409' // 小科ID
export const serviceId = environment === 'online' || environment === 'tprod' ? '218390897688577' : '278190260748289' // 客服ID
export const newTechId = '158026997563393' // 新技术频道 environment === 'uat' ? '246310135463937' :
export const personalTypeId = "241187227238401"  // 个人类型的企业Id environment === "uat" ? "239943184089089" :
export const searchPageSize = 20  // 搜索每页个数
export const defaultImg = environment === 'uat' ? '/2022/06/11/1657503493604590' : '/2022/06/11/165750355225823' // 默认图配置
export const iurChannelId = environment === 'online' || environment === 'tprod' ? '160230236094465' : '236138631593985' // 产学研用频道Id
export const oralListId = environment === 'uat' ? '301878422339585' : '301878269247489';
export const darkThemeRoute = ['/'] // 展示暗色的路由
export const newYearThemeRoute = ['/']  // 展示新年主题的路由
export const hotChannelId = '223061752217601'  // 科企投条Id
export const recommendChannel = '146330213220353' // 推荐频道Id
export const bucketMap = baseURL !== "https://api.keqidao.com/" ?
  {
    image: "test-1304545655",
    video: "test-video-1304545655",
    file: "test-front-file-1304545655"  // uat文件桶
  } : {
    image: "ol-image-1304545655",
    video: "ol-video-1304545655",
    file: "front-file-1304545655"
  }

export const staticPath = `https://${bucketMap.image}.cos.ap-shanghai.myqcloud.com/`;
export const staticPath2 = `https://${bucketMap.image}.cos.ap-shanghai.myqcloud.com`;
export const videoStaticPath = `https://${bucketMap.video}.cos.ap-shanghai.myqcloud.com/`
export const fileStaticPath = `${bucketMap.file}.cos.ap-shanghai.myqcloud.com`
export const imStaticPath = 'https://a1.easemob.com/1112210205042338/keqidao/chatfiles/'
export const fileFullPath = `https://${bucketMap.file}.cos.ap-shanghai.myqcloud.com`
export const requestVersion = '2.2.4'; // 接口版本2.2.4


export const fullDefaultImg = environment === 'uat' ? `${staticPath}/2022/06/11/1657503493604590` : `${staticPath}/2022/06/11/165750355225823` // 默认图配置

// export const kqdPath = 'http://localhost:3000' // 开发环境
export const kqdPath = environment === 'uat' ? 'https://uwww.keqidao.com' : environment === 'tprod' ? 'https://tprod.keqidao.com' : 'https://www.keqidao.com' // kdq前缀
export const kqdH5Path = environment === 'uat' ? 'https://umobile.keqidao.com' : 'https://mobile.keqidao.com' // kdq H5前缀

export const iframeUrl = ['/chat-list']

// 官网 QQ 和 微信 appid 及 secret/key
export const weChatAppid = 'wxbca308e43b2f57bd'
export const weChatSecret = '0698d27e70d00b1b1490df25300efeaa'

export const qqAppid = '101993065'
export const qqKey = '5a982085fae5364f246e8a86f98e5606'


export const routeHasTim = ['/','/home','/live','/live-room','/conference',...talentProjectSystemPage,...G60Page] // 需要登录TIM的路由
export const routeNewSearchBar = ['']  // 显示有tim的bar但不初始化tim
export const routeNewBarTim = ['/search-center','/search-demand','/searchDynamic','/enterpriseDynamic','/search','/huiyi','/user-center','/detail','/c-center']  // 新导航栏显示TIM的路由
export const otherTim = ['/lab','/my-lab','/my-lab-doctor','/demand-detail','/business-vip','/incubator'] // 其他需要聊天功能的路由
export const TimNoShow = ['/business-vip']  // 不展示窗口的聊天
export const outsidePage = ['/incubator']
export const talentProjectSystemPage = ['/talent-project-login','/talent-project','/talent-project-talents','/talent-project-user','/talent-project-detail','/talent-project-projects','/talent-project-temp']
export const G60OutLink = ['/out-demand','/out-project','/out-expert']
export const G60Page = ['/','/login','/space','/demands','/experts','/achieves','/login','/user','/search','/publish-demand','/publish-project','/detail','/vip','/expert-settle','/selection','/publish-dynamic','/news','/questions','/policies','/announce','/not-found',...G60OutLink]
export const G60WhitePage = ['/','/login','/space','/vip','/demands','/experts','/achieves','/login','/search','/detail','/news','/questions','/policies','/announce','/not-found',...G60OutLink]
export const TanWhitePage = ['/','/not-found','/tan-invite']
export const TanNotShowNavbar = ['/identity','/tan-invite']
export const TanNotShowFooter = ['/identity','/tan-invite']
export const AIWhitePage = ['/','/share']
export const PLWhitePage = ['/','/info','/login','/not-found']
export const picPage = ['/business-page','/business-page2','/G60-page','/G60-assembly','/regional-partner','/incubator-new']
export const k_customBar = ['/p-center','/expert-management','/expert-detail','/resource-center','/order','/AI','/expert-search','/project-search','/project-detail','/team-management','/dynamic-center','/demand-publish','/demand-management',]


export const sectionPaths = ['/AI','/electronic-information','/new-energy','/physics','/new-material','/biology','/chip','/manufacture','/environment','/climate','/agriculture','/clothing','/glance','/electrochemistry','/industry-outlook','/policy','/frontier','/fashion','/forum','/facility','/park','/equipment','/character','/AR','/finance-capital','/commerce','/case','/focus','/insight']

export const deleteList = ['计算机/互联网/通信/电子','会计/金融/银行/保险','贸易/消费/制造/营运','制药/医疗','房地产/建筑','专业服务/教育/培训','服务业','物流运输','政府/非营利组织/其他','能源/环保/化工','广告/媒体','新一代信息技术产业','高端装备制造产业','新材料产业','相关服务业','生物产业','新能源汽车产业','新能源产业','节能环保产业','数字创业产业','航天航空技术','生物与新医药技术'] // 列表删除的行业大类

// 其余状态均为已经过期
export const LIVEBRFORESTART = 1  // 路演未开始
export const LIVEONTHERAIR = 2  // 路演进行中
export const LIVEPAUSED = 3 //  路演暂停中
export const LIVEHASREPLAY = 4  // 路演有回放



/**
 * 获取滚动条的宽度
 */
export function getScrollWidth() {
  const scroll = document.createElement("div");
  const scrollIn = document.createElement("div");
  scroll.appendChild(scrollIn);
  scroll.style.width = "100px";
  scroll.style.height = "50px";
  scroll.style.overflow = "scroll";
  scroll.style.marginLeft = "-100000px";
  document.body.appendChild(scroll);
  const scrollInWidth = scrollIn.offsetWidth;
  const scrollWidth = scroll.offsetWidth;
  const tmp = setTimeout(() => {
      document.body.removeChild(scroll);
      clearTimeout(tmp);
  }, 10);
  return scrollWidth - scrollInWidth;
}

export function decryptByDES (ciphertext, key) {  // 解密方法
  const CryptoJS = window.CryptoJS
  if(!CryptoJS){
    const script = document.createElement("script");
    script.src = 'https://front-file-1304545655.cos.ap-shanghai.myqcloud.com/2023/10/07/1699350275620305/pass.json';
    document.getElementsByTagName("head")[0].appendChild(script);
  }
  let keyHex = CryptoJS.enc.Utf8.parse(key);
  let decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
  }, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}