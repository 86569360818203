<template>
  <div>
    <div class="container">
      <div class="container contact-container">
        <div
          class="top-bg"
          :style="selected ? ' height: calc(100vh - 450px);' : ''"
        >
          <div class="company" v-loading="loading || selectionLoading()">
            <!-- <div class="selection-top">
              <p v-if="!isLabEdit">{{showImgUpload ? '我的企业' : '数字科创中心(个人)'}}</p>
              <p v-else>实验室信息修改</p>
            </div> -->
            <el-form
              ref="ruleCompany"
              :model="companyform"
              class="school-content"
              v-if="!isLabEdit"
            >
              <div
                v-for="(item, index) in companyform.params"
                :key="item.parameterId"
              >
                <div
                  class="item-form"
                  v-if="
                    ['企业名称', '园区名称', '单位名称'].indexOf(item.name) !==
                    -1
                  "
                >
                  <p>
                    {{ item.name }}：
                    <span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="name">
                    <!-- 名称不修改 -->
                    <el-input
                      :disabled="(item.isName || !isCeo) && !reVerify"
                      v-model="item.val"
                      placeholder="请输入名称"
                      class="input-css"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  class="item-form"
                  v-else-if="
                    item.name === '企业研发产品名称' ||
                    item.name === '企业产品研发名称' ||
                    item.name === '企业产品名称'
                  "
                >
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="productName">
                    <el-input
                      v-model="item.val"
                      placeholder="请输入产品名称"
                      class="input-css"
                      :disabled="!isCeo && !reVerify"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="item-form" v-else-if="item.name === '科研人员数'">
                  <p>
                    科研人员数：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="scientificResearchCount">
                    <el-input
                      v-model="item.val"
                      placeholder="请输入科研人员数"
                      class="input-css"
                      @input="handelNumber(index)"
                      :maxlength="40"
                      show-word-limit
                      :disabled="!isCeo && !reVerify"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="item-form" v-else-if="item.name === '企业研究机构'">
                  <p>
                    企业研究机构：<span
                      class="empty-remind"
                      v-if="
                        (item.valId?.length === 0 || !item.valId) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <el-select
                      style="width: 100%"
                      v-model="item.valId"
                      placeholder="请选择研究机构"
                      :disabled="!isCeo && !reVerify"
                    >
                      <el-option
                        v-for="item in organizations"
                        :key="item.id"
                        :label="item.val"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  class="item-form"
                  v-else-if="
                    [
                      '园区类型',
                      '园区内科员人员数量',
                      '是否需要引入高新企业',
                      '是否需要引入技术专家',
                      '是否引入高新企业',
                      '是否需要碳中和培训服务',
                      '园区内科研人员数量',
                    ].indexOf(item.name) !== -1
                  "
                >
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.valId?.length === 0 || !item.valId) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <el-select
                      style="width: 100%"
                      v-model="item.valId"
                      placeholder="请选择园区类型"
                      :disabled="!isCeo && !reVerify"
                    >
                      <el-option
                        v-for="jtem in item.selections"
                        :key="jtem.id"
                        :label="jtem.val"
                        :value="jtem.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- select选项 -->
                <div class="item-form" v-else-if="item.type === 'select'">
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.valId?.length === 0 || !item.valId) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <el-select
                      style="width: 100%"
                      v-model="item.valId"
                      :placeholder="'请选择' + item.name"
                      :disabled="!isCeo && !reVerify"
                    >
                      <el-option
                        v-for="jtem in item.selections"
                        :key="jtem.id"
                        :label="jtem.val"
                        :value="jtem.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- 园区数量 -->
                <div class="item-form" v-else-if="item.name === '园区数量'">
                  <p>
                    园区数量：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <el-input
                      v-model="item.val"
                      placeholder="请输入园区数量"
                      class="input-css"
                      @input="handelNumber(index)"
                      :maxlength="40"
                      show-word-limit
                      :disabled="!isCeo && !reVerify"
                    ></el-input>
                  </el-form-item>
                </div>
                <!-- 园区内企业数量 -->
                <div
                  class="item-form"
                  v-else-if="
                    item.name === '园区内企业数量' ||
                    item.name === '园区企业数量'
                  "
                >
                  <p>
                    园区内企业数量：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <el-input
                      v-model="item.val"
                      placeholder="请输入园区内企业数量"
                      class="input-css"
                      @input="handelNumber(index)"
                      :maxlength="40"
                      show-word-limit
                      :disabled="!isCeo && !reVerify"
                    ></el-input>
                  </el-form-item>
                </div>
                <!-- 主导产业 -->
                <div
                  class="item-form"
                  v-else-if="
                    item.name === '主导产业' && item.type !== 'industrys'
                  "
                >
                  <p>
                    主导产业：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="mainIndustry">
                    <el-input
                      :disabled="!isCeo && !reVerify"
                      v-model="item.val"
                      placeholder="请输入名称"
                      class="input-css"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="item-form" v-else-if="item.name === '研究经费'">
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="researchFund">
                    <el-input
                      v-model="item.val"
                      placeholder="请输入研究经费"
                      class="input-css"
                      @input="handelNumber(index)"
                      :maxlength="40"
                      show-word-limit
                      :disabled="!isCeo && !reVerify"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  class="item-form"
                  v-else-if="
                    ['企业地址', '园区地址', '单位地址', '地址'].indexOf(
                      item.name
                    ) !== -1
                  "
                >
                  <p>
                    请选择{{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        location.length === 0 &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <el-cascader
                      ref="cascaderAddr"
                      :props="props"
                      style="width: 100%; background: #f3f3f3 !important"
                      placeholder="请选择地址"
                      :disabled="!isCeo && !reVerify"
                      v-model="location"
                      @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"
                    ></el-cascader>
                  </el-form-item>
                </div>
                <div
                  class="item-form"
                  v-else-if="item.name === '技术人员研究方向'"
                >
                  <p>
                    技术人员研究方向：<span
                      class="empty-remind"
                      v-if="
                        (item.valId?.length === 0 || !item.valId) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <!-- 1.7 行业多选 -->
                  <el-form-item prop="industryIds">
                    <el-select
                      style="width: 100%"
                      v-model="item.valId"
                      placeholder="请选择研究方向"
                      :disabled="!isCeo && !reVerify"
                    >
                      <el-option
                        v-for="item in industrylist"
                        :key="item.id"
                        :label="item.val"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- 其他输入 -->
                <div class="item-form" v-else-if="item.type === 'input'">
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="productName">
                    <!-- 名称不修改 -->
                    <el-input
                      v-model="item.val"
                      :placeholder="'请输入' + item.name"
                      class="input-css"
                      :disabled="
                        ((item.isName && showImgUpload) || !isCeo) && !reVerify
                      "
                      :maxlength="!showImgUpload ? 10 : 40"
                    ></el-input>
                  </el-form-item>
                </div>
                <!-- inputNumber -->
                <div class="item-form" v-else-if="item.type === 'inputNumber'">
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.val?.length === 0 || !item.val) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item prop="productName">
                    <el-input
                      v-model="item.val"
                      :placeholder="'请输入' + item.name"
                      class="input-css"
                      @input="handelNumber(index)"
                      :maxlength="40"
                      show-word-limit
                      :disabled="!isCeo && !reVerify"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="item-form" v-else-if="item.type === 'industrys'">
                  <p>
                    {{ item.name }}：<span
                      class="empty-remind"
                      v-if="
                        (item.valId?.length === 0 || !item.valId) &&
                        item.notNull === 1 &&
                        submitClicked
                      "
                      >{{ item.nullTip }}</span
                    >
                  </p>
                  <el-form-item>
                    <!-- 行业多选 -->
                    <MultiSelection
                      :selections="multiIndustry"
                      :placeholder="'请选择' + item.name"
                      :max="10"
                      :ref="'industrys'"
                      :value="item.valId"
                      :disabled="!isCeo && !reVerify"
                    ></MultiSelection>
                  </el-form-item>
                </div>
                <!-- 自定义字段 -->
                <div class="item-form" v-if="index === 0 && showImgUpload">
                  <p>
                    姓名：
                    <span
                      class="empty-remind"
                      v-if="staffName.length === 0 && submitClicked"
                      >请输入姓名</span
                    >
                  </p>
                  <el-form-item prop="name">
                    <el-input
                      :disabled="!isCeo && !reVerify"
                      v-model="staffName"
                      placeholder="请输入姓名"
                      class="input-css"
                      :maxlength="10"
                    ></el-input>
                  </el-form-item>
                </div>
                <!-- 部门 -->
                <div class="item-form" v-if="index === 0 && showImgUpload">
                  <p>
                    部门：<span
                      class="empty-remind"
                      v-if="(departmentId + '').length === 0 && submitClicked"
                      >请选择部门</span
                    >
                  </p>
                  <el-form-item>
                    <el-select
                      style="width: 100%"
                      v-model="departmentId"
                      :placeholder="'请选择部门'"
                      :disabled="
                        (!isCeo || departmentSelections.length === 0) &&
                        !reVerify
                      "
                    >
                      <el-option
                        v-for="jtem in departmentSelections"
                        :key="jtem.id"
                        :label="jtem.val"
                        :value="jtem.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <!-- 职位 -->
                <div class="item-form" v-if="index === 0 && showImgUpload">
                  <p>
                    职位：<span
                      class="empty-remind"
                      v-if="(positionId + '').length === 0 && submitClicked"
                      >请选择职位</span
                    >
                  </p>
                  <el-form-item>
                    <el-select
                      style="width: 100%"
                      v-model="positionId"
                      :placeholder="'请选择职位'"
                      :disabled="
                        (!isCeo || positionSelections.length === 0) && !reVerify
                      "
                    >
                      <el-option
                        v-for="jtem in positionSelections"
                        :key="jtem.id"
                        :label="jtem.val"
                        :value="jtem.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="item-form" v-if="showImgUpload">
                <p style="margin-bottom: 0">上传企业认证信息：</p>
                <div class="tip">营业执照、身份证、工作证、名片等都可以</div>
                <div class="tip-small">仅作为认证信息，将不会对外展示</div>
                <el-form-item>
                  <el-upload
                    ref="upload1"
                    action="#"
                    :disabled="!isCeo && !reVerify"
                    :limit="9"
                    :accept="'image/*'"
                    list-type="picture-card"
                    :on-exceed="handleExceed"
                    :http-request="handleUpload"
                    :on-change="loadJsonFromFile"
                    :before-upload="beforeUpload"
                    :file-list="loadedImgs"
                  >
                    <template #default>
                      <i class="el-icon-plus"></i>
                    </template>
                    <template #file="{ file }">
                      <el-progress
                        v-if="file.percentage != 100"
                        type="circle"
                        :percentage="file.percentage"
                      ></el-progress>
                      <div
                        v-if="file.percentage == 100"
                        style="position: relative; width: 100%; height: 100%"
                      >
                        <img
                          v-if="file.raw.type[0] == 'i'"
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <div
                          @click="deleteVideo(file)"
                          class="icon-delete"
                          v-if="isCeo"
                        >
                          <i
                            class="el-icon-delete"
                            style="fontsize: 16px; color: #fff"
                          ></i>
                        </div>
                      </div>
                    </template>
                  </el-upload>
                </el-form-item>
              </div>
              <div v-for="item in extraParams" :key="item.key">
                <div v-if="item.type === 'input'" class="item-form">
                  <p class="company-summary">{{ item.nullTip }}:</p>
                  <!-- 附加输入框 -->
                  <div v-if="item.type === 'input'">
                    <el-input
                      :disabled="!isCeo && !reVerify"
                      v-model="item.val"
                      :maxlength="item.limit"
                      :placeholder="item.nullTip"
                      show-word-limit
                    ></el-input>
                  </div>
                  <!-- 附加输入区域 -->
                  <div v-if="item.type === 'textarea' && isNaN(item.key)">
                    <el-form-item prop="summary">
                      <el-input
                        :disabled="!isCeo && !reVerify"
                        :rows="9"
                        v-model="item.val"
                        type="textarea"
                        :minlength="item.min"
                        :maxlength="item.limit"
                        :placeholder="item.nullTip"
                        show-word-limit
                        resize="none"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <!-- 附加行业 -->
                  <div v-if="item.type == 'qidaoIndustry'">
                    <el-select
                      v-model="item.val"
                      :placeholder="item.nullTip"
                      style="width: 100%"
                      :disabled="!isCeo && !reVerify"
                    >
                      <el-option
                        v-for="item in industrylist"
                        :key="item.id"
                        :label="item.val"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 附加地址 -->
                  <div v-if="item.type === 'qidaoAddress' && isNaN(item.key)">
                    <el-form-item>
                      <el-cascader
                        :ref="'AddrExtra' + item.id"
                        :props="props"
                        style="width: 100%; background: #f3f3f3 !important"
                        :placeholder="item.nullTip"
                        :disabled="!isCeo && !reVerify"
                        v-model="extraLocation"
                        @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"
                      ></el-cascader>
                    </el-form-item>
                  </div>
                  <!-- 附加时间 -->
                  <div v-if="item.type === 'picker'">
                    <el-date-picker
                      v-model="item.val"
                      :clearable="false"
                      type="date"
                      :editable="false"
                      :disabled="!isCeo && !reVerify"
                      :disabledDate="disabledDate"
                      :placeholder="item.nullTip"
                      class="input-css"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="window-btn-company" v-if="isCeo">
                <el-button
                  :loading="btnDisabled"
                  :disabled="btnDisabled"
                  @click="submitCompany(0)"
                  class="cancel fs-18 fc-fff"
                  >取消修改</el-button
                >
                <el-button
                  :loading="btnDisabled"
                  :disabled="btnDisabled"
                  @click="submitCompany(1)"
                  class="confirm fs-18 fc-fff"
                  >确认提交</el-button
                >
              </div>
              <div class="window-btn-company" v-else>
                <el-button
                  :loading="btnDisabled"
                  :disabled="btnDisabled"
                  @click="submitCompany(0)"
                  class="cancel fs-18 fc-fff"
                  >取消修改</el-button
                >
              </div>
            </el-form>
            <!-- 实验室修改 -->
            <el-form
              :rules="rulesLab"
              ref="ruleLab"
              :model="labform"
              class="school-content"
              v-else
            >
              <div class="item-form">
                <div class="disabled">所属单位：</div>
                <el-form-item prop="name">
                  <el-input
                    :disabled="true && !reVerify"
                    v-model="labform.belong"
                    placeholder="请输入所属单位名称"
                    class="input-css"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="item-form">
                <div class="disabled">实验室名称：</div>
                <el-form-item prop="name">
                  <el-input
                    :disabled="true && !reVerify"
                    v-model="labform.name"
                    placeholder="请输入实验室名称"
                    class="input-css"
                  ></el-input>
                </el-form-item>
              </div>
              <!-- <div class="item-form">
                <p>姓名：</p>
                <el-form-item prop="name">
                  <el-input  v-model="labform.createMan" placeholder="请输入姓名" class="input-css"></el-input>
                </el-form-item>
              </div> -->
              <div class="item-form">
                <p>实验室研究方向：</p>
                <el-form-item prop="label">
                  <el-select
                    style="width: 100%; background: #f3f3f3 !important"
                    class="width360"
                    :disabled="resUserInfo.role === 4 && !reVerify"
                    v-model="labform.label"
                    multiple
                    filterable
                    :filter-method="findLabel"
                    allow-create
                    default-first-option
                    placeholder="最多输入5个,输入单个方向后按enter键确认"
                  >
                  </el-select>
                </el-form-item>
              </div>
              <!-- <div class="item-form">
                <p>实验室规模：(请重新选择)</p>
                <el-form-item prop="techScaleId">
                <el-select v-model="labform.techScaleId" placeholder="请输入实验室规模">
                  <el-option
                      v-for="item in humanlist"
                      :key="item.id"
                      :label="item.val"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              </div> -->
              <!-- <div class="item-form">
                <p>邮箱：</p>
                <el-form-item prop="name">
                  <el-input  v-model="labform.email" placeholder="请输入邮箱" class="input-css"></el-input>
                </el-form-item>
              </div> -->
              <!-- <div class="item-form">
                <p>学历：</p>
                  <el-form-item prop="eduId" >
                  <el-select v-model="labform.educationId" placeholder="请选择行业">
                    <el-option
                      v-for="item in Educationlist"
                      :key="item.id"
                      :label="item.val"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="item-form">
                <p>职称：</p>
                  <el-form-item prop="eduId" >
                  <el-select v-model="labform.positionId" placeholder="请选择行业">
                    <el-option
                      v-for="item in jobtitlelist"
                      :key="item.id"
                      :label="item.val"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div> -->
              <div class="item-form">
                <p>请选择实验室地址：</p>
                <el-form-item>
                  <el-cascader
                    ref="cascaderAddr"
                    :props="props"
                    style="width: 100%; background: #f3f3f3 !important"
                    placeholder="请选择实验室地址"
                    :disabled="resUserInfo.role === 4 && !reVerify"
                    v-model="location"
                    @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange"
                  ></el-cascader>
                </el-form-item>
              </div>
              <div class="item-form">
                <p>请选择技术可应用行业：</p>
                <!-- 1.7 行业多选 -->
                <el-form-item prop="industryIds">
                  <el-select
                    style="width: 100%"
                    v-model="labform.industryIds"
                    multiple
                    :multiple-limit="10"
                    placeholder="请选择行业"
                    :disabled="resUserInfo.role === 4 && !reVerify"
                  >
                    <el-option
                      v-for="item in industrylist"
                      :key="item.id"
                      :label="item.val"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- 1.8.4 自我介绍 -->
              <div class="item-form">
                <p>请输入自我介绍：</p>
                <el-form-item>
                  <el-input
                    v-model="labform.orgIntroduction"
                    type="textarea"
                    :maxlength="400"
                    show-word-limit
                    placeholder="请输入自我介绍"
                    resize="none"
                    rows="9"
                    :disabled="resUserInfo.role === 4 && !reVerify"
                  />
                </el-form-item>
              </div>
              <div class="item-form">
                <p>上传实验室铭牌或教师职工工牌：</p>
                <el-form-item>
                  <el-upload
                    ref="upload1"
                    :disabled="resUserInfo.role === 4 && !reVerify"
                    action="#"
                    :limit="9"
                    :accept="'image/*'"
                    list-type="picture-card"
                    :on-exceed="handleExceed"
                    :http-request="handleUpload"
                    :on-change="loadJsonFromFile"
                    :before-upload="beforeUpload"
                    :file-list="loadedImgs"
                  >
                    <template #default>
                      <i class="el-icon-plus"></i>
                    </template>
                    <template #file="{ file }">
                      <el-progress
                        v-if="file.percentage != 100"
                        type="circle"
                        :percentage="file.percentage"
                      ></el-progress>
                      <div
                        v-if="file.percentage == 100"
                        style="position: relative; width: 100%; height: 100%"
                      >
                        <img
                          v-if="file.raw.type[0] == 'i'"
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <div
                          @click="deleteVideo(file)"
                          class="icon-delete"
                          v-if="resUserInfo.role !== 4"
                        >
                          <i
                            class="el-icon-delete"
                            style="fontsize: 16px; color: #fff"
                          ></i>
                        </div>
                      </div>
                    </template>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="window-btn-company" v-if="resUserInfo.role !== 4">
                <el-button
                  :loading="btnDisabled"
                  :disabled="btnDisabled"
                  @click="submitLab(0)"
                  class="cancel fs-18 fc-fff"
                  >取消修改</el-button
                >
                <el-button
                  :loading="btnDisabled"
                  :disabled="btnDisabled"
                  @click="submitLab(1)"
                  class="confirm fs-18 fc-fff"
                  >确认提交</el-button
                >
              </div>
              <div class="window-btn-company" v-else>
                <el-button
                  :loading="btnDisabled"
                  :disabled="btnDisabled"
                  @click="submitLab(0)"
                  class="cancel fs-18 fc-fff"
                  >取消修改</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { memberMessageDetail, getUserVerifyStatus } from "@/api/user";
import { bucketMap, personalTypeId, deleteList } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import {
  uploadTypeImage,
  uploadTypeVideo,
  cosByUploadType,
} from "@/api/upload";
import { memberDetails } from "@/api/user";
import { getprovince, getcity, getarea, getSelect } from "@/api/demand";
import { editOrg, memberMessage, editLab } from "@/api/user";
import { mapGetters } from "vuex";
import { ElMessage } from "element-plus";
import {
  getEnterpriseInfo,
  getLabInfo,
  getLabInfoByMemberId,
} from "@/api/organization";
import { staticPath } from "@/utils/consts";
import { G60site,TanSite } from "@/router/index";
import { getOrganizationTemplate } from "@/api/organization"; // 获取模板 及 版本
import { deepClone } from "@/utils/tool";
import MultiSelection from "@/components/MultiSelection/MultiSelection";
import { getSelectFatherSonByType } from "@/api/config";
import { routerWindow } from "@/utils/tool";
import { getG60LocalInfo } from "@/utils/G60/login";
import { getTanLocalInfo } from "@/utils/Tan/login";
import { tanJudge } from "@/utils/auth";

const labelRule = function (rule, value, callback) {
  if (value.length > 5) {
    callback(new Error("标签不能超过5个"));
  } else {
    callback();
  }
};

export default {
  name: "orgnization-edit",
  components: { MultiSelection },
  emits: ['changeOrgEditTitle'],
  data() {
    return {
      reVerify: false, // 重新认证
      staffName: "", // 员工名字
      positionId: "", // 职位Id
      positionSelections: [], // 职位选项
      departmentId: "", // 部门Id
      departmentSelections: [], // 部门选项
      submitClicked: false, // 是否点击过按钮
      organizations: [], // 企业研究机构
      extraLocation: null, // 附加地址
      resUserInfo: {}, // 接口获取用户信息
      isCeo: false, // 是否首席执行官
      templateVersion: -1, // 模板版本
      extraParams: [], // 附加参数
      createdImgs: [],
      loadedImgs: [],
      uploadimgs: [],
      staticPath,
      humanlist: [],
      jobtitlelist: [],
      multiIndustry: [], // 行业多选
      Educationlist: [],
      industrylist: [],
      industryIds: [], // 1.7 组织机构行业多选
      isLabEdit: false,
      licenseImgs: [],
      btnDisabled: false,
      directionLabel: "",
      loading: false,
      loadingSelection: [], // 参数加载
      ElMessage: ElMessage,
      location: [],
      selected: false,
      organizationId: -1,
      showImgUpload: true, // 是否展示上传图片
      companyform: {
        // 1.6.1公司表单
        addressAreaId: 1,
        addressAreaName: "",
        addressCityId: 1,
        addressCityName: "",
        addressProvinceId: 1,
        addressProvinceName: "",
        memberId: "",
        industryIds: [],
        label: [],
        license: [],
        name: "",
        companyType: "",
        summary: "",
      },
      labform: {
        addressAreaId: 1,
        addressAreaName: "",
        addressCityId: 1,
        addressCityName: "",
        addressProvinceId: 1,
        addressProvinceName: "",
        belong: "",
        createMan: "",
        educationId: "",
        qualifications: "",
        email: "",
        industryIds: [],
        label: [],
        name: "",
        operator: "",
        organizationId: "",
        phone: "",
        positionId: "",
        scaleId: 100,
        summary: "",
        orgIntroduction: null,
        // "techScaleId": '',
      },
      dialogVisible: false,
      dialogImageUrl: "",
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then((response) => {
                const cities = [];
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false,
                  });
                }
                resolve(cities);
              });
            }
            if (node.level == 1) {
              getcity(node.value).then((response) => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false,
                  });
                }
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then((response) => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true,
                  });
                }
                resolve(areas);
              });
            }
          }, 100);
        },
      },
      rulesLab: {
        label: [
          { required: true, message: "请正确输入方向", trigger: "change" },
          { required: true, validator: labelRule },
        ],
        // 行业是否已选判断
        industryIds: [
          {
            required: true,
            message: "请选择技术可应用行业",
            trigger: "change",
          },
        ],
      },
    };
  },
  async beforeCreate() {
    let userInfos = localStorage.getItem("userInfos");
    userInfos = JSON.parse(userInfos);
    if(G60site){
      userInfos = getG60LocalInfo()
    }
    else if(TanSite){
      userInfos = getTanLocalInfo()
    }
    let res = await memberDetails(userInfos.memberInfo.id); // 获取用户信息
    this.resUserInfo = res.data;
    this.createdImgs = res.data.qualifications?.map((item) => {
      if (item.indexOf(staticPath) !== -1) {
        // 参数剔除
        item = item.slice(staticPath.length);
      }
      return item;
    });
    if(G60site){
      if (
        res.data.organizationId == null &&
        userInfos.memberInfo.organizationId == null
      ) {
        // 若没有组织，前往完善组织信息
        routerWindow(this.$router,'/settle',{})
      }
      return
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let { organizationId, organizationType } = res.data;
    userInfo = {
      ...userInfo,
      organizationId,
      organizationType,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    this.$store.dispatch("user/changeVipStatus", res.data.isVip); // 设定Vip状态
    if (
      res.data.organizationId == null &&
      userInfos.memberInfo.organizationId == null
    ) {
      // 若没有组织，前往完善组织信息
      tanJudge()
    }
  },
  async created() {
    this.organizationId = -1;
    this.loading = true;
    this.$store.commit("message/SET_ACTIVENAV", "官网首页");
    this.$store.commit("message/SET_BG", false);
    let id = this.id
    if(G60site){
      id = getG60LocalInfo()?.memberInfo?.id
    }
    else if(TanSite){
      id = getTanLocalInfo()?.memberInfo?.id
    }
    let res = await memberMessage(id); // 获取用户信息
    if (!res.data.organizationId) {
      return;
    }
    let verifyStatus = await getUserVerifyStatus({ id: id });
    this.reVerify = verifyStatus.data.verifyStatus + "" === "3";
    if (res.data.type === 1) {
      // 是企业
      let requestdata = {
        limit: 100,
        offset: 1,
        type: 6,
      };
      // 获取行业列表
      let list = await getSelect(requestdata);
      this.industrylist = list.data.result;
      if (res.data.organizationId) {
        this.organizationId = res.data.organizationId;
      }
      const industrys = await getSelectFatherSonByType(6);
      this.multiIndustry = industrys.data;
      let res2 = await getEnterpriseInfo(res.data.organizationId); //  获取企业信息
      res2.data.memberResList.find((member) => {
        if (member.id + "" === id + "" && member.role === 2) {
          this.isCeo = true;
          return true;
        }
      });
      this.companyform = deepClone(res2.data.organizationDto); // 深拷贝
      this.companyform.params.map((item) => {
        if (
          [
            "企业研究机构",
            "技术人员研究方向",
            "园区类型",
            "园区内科员人员数量",
            "是否需要引入高新企业",
            "是否需要引入技术专家",
            "是否需要碳中和培训服务",
            "园区内科研人员数量",
            "是否引入高新企业",
          ].indexOf(item.name) !== -1
        ) {
          item.valId = Number(item.valId);
        }
      });
      let params = await getOrganizationTemplate(this.companyform.typeId); // 获取模板
      if (this.companyform.typeId + '' === personalTypeId + '') this.showImgUpload = false; // 个人类型不展示图片上传
      if (this.showImgUpload) {
        // 非个人类型获取选项 及 填入参数
        this.getNewSelections();
        this.staffName = res.data.name ? res.data.name : "";
        this.positionId = res.data.companyPositionId
          ? res.data.companyPositionId
          : "";
        this.departmentId = res.data.companyDepartmentId
          ? res.data.companyDepartmentId
          : "";
      }
      this.templateVersion = params.data.version; // 保存版本号
      this.extraParams = params.data.params
        ? params.data.params.map((item, index) => {
            // 对参数格式限制
            if (item.type === "input") {
              let isName = false; // 是否名称输入
              if (item.additionCheck?.fieldName === "name") {
                isName = true;
              }
              if (
                [
                  "园区数量",
                  "园区内企业数量",
                  "研究经费",
                  "科研人员数",
                  "园区企业数量",
                ].indexOf(item.name) !== -1 ||
                item.additionType === "number"
              ) {
                return {
                  isName: isName,
                  notNull: item.notNull,
                  key: index,
                  name: item.name,
                  title: item.title,
                  type: "inputNumber",
                  nullTip: item.subTitle,
                  parameterId: item.id,
                  val: "",
                  limit: 20,
                };
              }
              return {
                isName: isName,
                notNull: item.notNull,
                key: index,
                name: item.name,
                title: item.title,
                type: item.type,
                nullTip: item.subTitle,
                parameterId: item.id,
                val: "",
                limit: item.additionCheck.maxlength
                  ? item.additionCheck.maxlength
                  : 40,
              };
            } else if (item.type === "qidaoIndustry") {
              return {
                notNull: item.notNull,
                key: index,
                name: item.name,
                title: item.title,
                type: "industrys",
                nullTip: item.subTitle,
                parameterId: item.id,
                val: "",
                limit: "",
              };
            } else if (item.name === "企业研究机构") {
              return {
                notNull: item.notNull,
                additionType: item.additionType,
                key: index,
                name: item.name,
                nullTip: item.subTitle,
                limit: 20,
                parameterId: item.id,
                val: "",
                type: "organization",
                subTitle: item.subTitle,
              };
            } else if (item.name === "园区类型") {
              return {
                notNull: item.notNull,
                additionType: item.additionType,
                key: index,
                name: item.name,
                nullTip: item.subTitle,
                limit: 20,
                parameterId: item.id,
                val: "",
                type: "gardenType",
                subTitle: item.subTitle,
              };
            } else if (
              item.name === "园区内科员人员数量" ||
              item.name === "园区内科研人员数量"
            ) {
              return {
                notNull: item.notNull,
                additionType: item.additionType,
                key: index,
                name: "园区内科研人员数量",
                nullTip: item.subTitle,
                limit: 20,
                parameterId: item.id,
                val: "",
                type: "gardenNumber",
                subTitle: item.subTitle,
              };
            } else if (
              [
                "是否引入高新企业",
                "是否需要引入高新企业",
                "是否需要引入技术专家",
                "是否需要碳中和培训服务",
              ].indexOf(item.name) !== -1
            ) {
              return {
                notNull: item.notNull,
                additionType: item.additionType,
                key: index,
                name: item.name,
                nullTip: item.subTitle,
                limit: 20,
                parameterId: item.id,
                val: "",
                type: "twoWaySelect",
                subTitle: item.subTitle,
              };
            } else if (item.type === "select") {
              return {
                notNull: item.notNull,
                additionType: item.additionType,
                key: item.key,
                name: item.name,
                title: item.title,
                type: item.type,
                nullTip: item.subTitle,
                parameterId: item.id,
                val: "",
              };
            } else {
              return {
                notNull: item.notNull,
                key: item.key,
                name: item.name,
                title: item.title,
                type: item.type,
                nullTip: item.subTitle,
                parameterId: item.id,
                val: "",
              };
            }
          })
        : [];
      if (
        this.companyform.params?.length === 0 ||
        this.companyform.version < this.templateVersion
      ) {
        // 说明是老企业 1.10.13
        this.companyform.params = this.extraParams;
        this.companyform.params.map((item) => {
          if (item.isName) {
            // 名称赋值
            item.val = this.companyform.name;
          }
        });
      } else {
        // 新企业
        if (this.companyform.params.length < this.extraParams.length) {
          // 说明有非必填没填
          this.extraParams.forEach((item, i) => {
            // 从模板里查找
            let flag = false;
            this.companyform.params.find((jtem) => {
              if (jtem.parameterId === item.parameterId) {
                // 没有说明可以为空，插入
                flag = true;
                return true;
              }
            });
            if (!flag) this.companyform.params.splice(i, 0, item);
          });
        }
        this.companyform.params.map((item, index) => {
          if (this.extraParams[index].isName) {
            item.isName = this.extraParams[index].isName;
          }
          if (item.isName) {
            // 名称赋值
            item.val = this.companyform.name;
          }
          item.type = this.extraParams[index].type;
          item.notNull = this.extraParams[index].notNull;
          item.nullTip = this.extraParams[index].nullTip;
          if (item.valId && (item.valId + "").indexOf(",") === -1)
            item.valId = Number(item.valId);
        });
      }
      this.extraParams.forEach(async (item, index) => {
        if (item.name === "企业研究机构" && item.additionType) {
          let requestdata = {
            limit: 100,
            offset: 1,
            type: item.additionType,
          };
          let res = await getSelect(requestdata);
          this.organizations = res.data.result;
        } else if (item.additionType) {
          this.loadingSelection.push({ a: index, b: true }); // 加载开始
          let requestdata = {
            limit: 100,
            offset: 1,
            type: item.additionType,
          };
          let res = await getSelect(requestdata);
          this.companyform.params.map((jtem) => {
            if (jtem.parameterId === item.parameterId) {
              // 根据id匹配
              jtem.selections = res.data.result;
            }
            return jtem;
          });
          this.loadingSelection.map((item) => {
            // 加载完成
            if (item.a === index) {
              item.b = false;
            }
            return item;
          });
        }
      });
      this.extraParams = [];
      this.industryIds = this.companyform.industryList
        ? this.companyform.industryList.map((item) => {
            // id 赋值
            return item.id;
          })
        : [];
      this.companyform.industryIds = this.industryIds;
      if (!res2.data.organizationDto.summary) {
        this.companyform.summary = "";
      }
      if (!res2.data.organizationDto.labelList) {
        res2.data.organizationDto.labelList = [];
      }
      let labelList = res2.data.organizationDto.labelList.map((item) => {
        return item.val;
      });
      this.companyform.label = labelList;
      if (this.companyform.typeName == null) {
        // 空判断
        switch (this.companyform.companyType) {
          case 1:
            this.companyform.typeName = "资本投资类";
            break;
          case 2:
            this.companyform.typeName = "政策服务类";
            break;
          case 3:
            this.companyform.typeName = "技术需求类";
            break;
          case 4:
            this.companyform.typeName = "园区招商类";
            break;
        }
      }
      switch (this.companyform.typeName) {
        case "资本投资类":
          this.companyform.companyType = "资本投资类";
          this.directionLabel = "资本投资";
          break;
        case "政策服务类":
          this.companyform.companyType = "政策服务类";
          this.directionLabel = "政策服务";
          break;
        case "园区招商类":
          this.companyform.companyType = "园区招商类";
          this.directionLabel = "园区招商";
          break;
        default:
          this.companyform.companyType = "技术需求类";
          this.directionLabel = "技术需求类";
          break;
      }
      let memberId = res2.data.memberResList.find((item) => {
        if (item.role === 2) {
          // 找到管理
          return item;
        }
      });
      let imgs = res2.data.organizationDto.license;
      if (imgs?.length > 0 && imgs[0].length !== 0) {
        imgs = imgs.map((item) => {
          return {
            url: this.staticPath + item,
            percentage: 100,
            raw: {
              type: "image",
            },
          };
        });
        this.loadedImgs = imgs;
      }
      this.companyform.memberId = memberId.id;
      this.location = [
        this.companyform.addressProvinceId,
        this.companyform.addressCityId,
        this.companyform.addressAreaId,
      ];
      setTimeout(() => {
        this.$nextTick(()=>{
          // 防止崩溃
          const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
          Array.from($el).map((item) => item.removeAttribute('aria-owns'));
        })
        this.loading = false;
      }, 1000);
    } else {
      // 修改实验室
      let userInfo = localStorage.getItem("userInfo");
      userInfo = JSON.parse(userInfo);
      let userInfos = localStorage.getItem("userInfos");
      userInfos = JSON.parse(userInfos);
      let user = await memberMessageDetail(userInfo.id);
      let labInfo3 = await getLabInfo(res.data.organizationId);
      let labIntroduction = await getLabInfoByMemberId(userInfo.id); // 获取自我介绍
      this.$emit('changeOrgEditTitle',labIntroduction.data)
      let requestdata = {
        limit: 100,
        offset: 1,
        type: 6,
      };
      // 获取行业列表
      let list = await getSelect(requestdata);
      this.industrylist = list.data.result;
      let imgs = this.createdImgs;
      if (imgs?.length > 0 && imgs[0].length !== 0) {
        imgs = imgs.map((item) => {
          return {
            url: this.staticPath + item,
            percentage: 100,
            raw: {
              type: "image",
            },
          };
        });
        this.loadedImgs = imgs;
      }
      this.industryIds = labInfo3.data.industry;
      for (let i = 0; i < this.industryIds.length; i++) {
        // 实验室行业多选及赋值
        this.industrylist.find((item) => {
          if (item.val === this.industryIds[i]) {
            this.industryIds[i] = item.id;
          }
        });
      }
      this.labform = {
        addressAreaId: 1,
        addressAreaName: labInfo3.data.areaName,
        addressCityId: 1,
        addressCityName: labInfo3.data.cityName,
        addressProvinceId: 1,
        addressProvinceName: labInfo3.data.provinceName,
        belong: labInfo3.data.belong,
        qualifications: [],
        createMan: user.data.name,
        email: user.data.email,
        educationId: user.data.educationId,
        industryIds: this.industryIds, //  1.7 多选
        positionId: user.data.positionId,
        label: labInfo3.data.label,
        name: labInfo3.data.name,
        operator: user.data.id,
        organizationId: user.data.organizationId,
        phone: userInfos.memberInfo.phone,
        scaleId: 100,
        summary: "",
        orgIntroduction: labIntroduction.data.selfIntroduction,
        // "techScaleId": '',
      };
      let province = await getprovince();
      let provinceId = "";
      province.data.find((item) => {
        if (item.provinceName == this.labform.addressProvinceName) {
          provinceId = item.provinceCode;
        }
      });
      let city = await getcity(provinceId);
      let cityId = "";
      city.data.find((item) => {
        if (item.cityName == this.labform.addressCityName) {
          cityId = item.cityCode;
        }
      });
      let area = await getarea(cityId);
      let areaId = "";
      area.data.find((item) => {
        if (item.areaName == this.labform.addressAreaName) {
          areaId = item.areaCode;
        }
      });
      this.location = [provinceId, cityId, areaId];
      this.isLabEdit = true;
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(()=>{
          // 防止崩溃
          const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
          Array.from($el).map((item) => item.removeAttribute('aria-owns'));
        })
      }, 1500);
    }
    let newList = [];
    this.industrylist.forEach((item) => {
      if (deleteList.indexOf(item.val) === -1) {
        newList.push(item);
      }
    });
    this.industrylist = newList;
  },
  computed: {
    ...mapGetters(["id"]),
  },
  methods: {
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    async getNewSelections() {
      // 获取新的选项
      let res = await getSelectFatherSonByType(90);
      this.positionSelections = res.data;
      res = await getSelectFatherSonByType(91);
      this.departmentSelections = res.data;
    },
    selectionLoading() {
      // 选项加载判断
      let flag = false;
      if (this.loadingSelection?.length === 0) return flag;
      this.loadingSelection.forEach((item) => {
        if (item.b) {
          flag = true;
        }
      });
      return flag;
    },
    handelNumber(index) {
      // 数字处理
      let pattern = new RegExp("^[0-9]|-$");
      this.companyform.params[index].val = Array.from(
        this.companyform.params[index].val
      )
        .filter((v) => pattern.test(v))
        .join("");
    },
    disabledDate(date) {
      // 时间限定
      return date.getTime() < Date.now() - 8.64e7;
    },
    // 搜索方法
    findLabel(val) {
      let flag = false; // 判断是否存在
      let i = -1;
      if (this.isLabEdit) {
        for (let index = 0; index < this.labform.label.length; index++) {
          if (this.labform.label[index] === val) {
            flag = true;
            i = index;
            break;
          }
        }
        if (flag) {
          this.labform.label[i] += " "; // 在后方加上空格
        }
      } else {
        for (let index = 0; index < this.companyform.label.length; index++) {
          if (this.companyform.label[index] === val) {
            flag = true;
            i = index;
            break;
          }
        }
        if (flag) {
          this.companyform.label[i] += " "; // 在后方加上空格
        }
      }
    },
    handleExceed() {
      //文件超出个数限制时的钩子
      ElMessage.warning("最多只能上传2张图片～");
    },
    async loadJsonFromFile(file, fileList) {
      this.uploadimgs = fileList;
      console.log('load json from file:',file,fileList);
    },
    beforeUpload(file) {
      if (file.type.split("/")[0] === "video") {
        ElMessage.warning("只能上传图片!");
        this.uploadimgs.pop();
      }
    },
    async handleUpload(val) {
      var result;
      if (val.file.type.split("/")[0] === "image") {
        // 上传的是图片
        result = await this.directUpload(val.file, uploadTypeImage);
        this.btnDisabled = false;
        let temp = result.Location.slice(result.Location.indexOf("/"));
        if (!this.licenseImgs) {
          this.licenseImgs = [temp];
        } else {
          this.licenseImgs.push(...this.licenseImgs, temp);
          this.licenseImgs = Array.from(new Set(this.licenseImgs));
        }
      }
    },
    directUpload(file, uploadType, thumb = false) {
      var self = this;
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo),
      };
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video;
      let bucket =
        uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video;
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket /* 必须 */,
            Region: "ap-shanghai" /* 存储桶所在地域，必须字段 */,
            Key: randomFilename() /* 必须 */,
            StorageClass: "STANDARD",
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.btnDisabled = true;
              if (thumb) {
                // 带封面的上传的组件
                self.$refs["upload"].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100);
                  }
                });
              } else {
                self.$refs["upload1"].uploadFiles.map((item) => {
                  if (item.raw.uid === file.uid) {
                    item.percentage = parseInt(progressData.percent * 100);
                  }
                });
              }
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    deleteVideo(file) {
      if (!file.name) {
        // 如果是从后台读入的图片
        let index = 0;
        for (let i = 0; i < this.loadedImgs.length; i++) {
          if (this.loadedImgs[i].url == file.url) {
            index = i;
            break;
          }
        }
        this.loadedImgs.splice(index, 1);
        index = -1;
        for (let i = 0; i < this.licenseImgs.length; i++) {
          if (file.url.indexOf(this.licenseImgs[i]) !== -1) {
            index = i;
            break;
          }
        }
        if (index !== -1) this.licenseImgs.splice(index, 1);
        return;
      }
      let arr = this.$refs["upload1"].uploadFiles;
      let index = arr.indexOf(file);
      this.$refs["upload1"].uploadFiles.splice(index, 1);
      this.licenseImgs = this.licenseImgs.filter((item, i) => {
        return i !== index;
      }); // 也要删除腾讯云直传的名称🌟
      let that = this;
      this.btnDisabled = true;
      setTimeout(function () {
        that.btnDisabled = false;
      }, 1000);
    },
    emailRule() {
      if (!this.labform.email || this.labform.email.length === 0) {
        this.ElMessage.warning("请填入邮箱");
        return false;
      }
      let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(this.labform.email)) {
        this.ElMessage.warning("邮箱格式不正确");
        return false;
      }
      return true;
    },
    async submitLab(type) {
      if (type === 0) {
        this.$emit("editInfo", { type: "1" }); // 关闭窗口
        return;
      }
      if (
        this.labform.orgIntroduction?.length > 0 &&
        this.labform.orgIntroduction?.length < 10
      ) {
        ElMessage.warning("自我介绍最少输入10个字符!");
        return;
      }
      if (this.labform.createMan.length === 0) {
        ElMessage.warning("请输入姓名");
        return;
      }
      if (this.labform.label.length === 0) {
        ElMessage.warning("请输入实验室研究方向");
        return;
      }
      if (this.labform.industryIds.length === 0) {
        // 判断为空
        ElMessage.warning("请选择技术可应用行业");
        return;
      }
      if (this.licenseImgs.length == 0 && this.loadedImgs.length == 0) {
        ElMessage.warning("上传实验室铭牌或教师职工工牌");
        return;
      }
      // 去除头尾空格后去重
      let arr = this.labform.label.map((item) => item.trim());
      let mySet = new Set(arr);
      if (mySet.size != arr.length) {
        ElMessage.warning("研究方向有重复值,已经去除重复值,请查看后提交");
        this.labform.label = [...mySet];
        return;
      }
      // let emailflag = this.emailRule()
      // if(!emailflag) return
      // if (this.labform.techScaleId.length === 0) {
      //   ElMessage.warning('请选择实验室规模')
      //   return
      // }
      this.labform.addressAreaId = parseInt(this.location[2]);
      this.labform.addressCityId = parseInt(this.location[1]);
      this.labform.addressProvinceId = parseInt(this.location[0]);

      this.labform.addressAreaName =
        this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2];
      this.labform.addressCityName =
        this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1];
      this.labform.addressProvinceName =
        this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0];
      let images = [];
      if (this.loadedImgs.length > 0) {
        this.loadedImgs.forEach((item) => {
          let start = item.url.indexOf(staticPath);
          let temp = item.url.slice(start + staticPath.length, item.length);
          images.push(temp);
        });
      }
      this.licenseImgs = [...images, ...this.licenseImgs];
      this.labform.qualifications = this.licenseImgs;
      this.btnDisabled = true;
      this.loading = true;
      try {
        let that = this;
        await editLab(this.labform);
        ElMessage.success("修改成功！");
        setTimeout(() => {
          that.$emit("editInfo", { type: "1" }); // 关闭窗口
        }, 1000);
        this.btnDisabled = false;
        this.loading = false;
      } catch (error) {
        this.btnDisabled = false;
        this.loading = false;
      }
    },
    async submitCompany(type) {
      if (type === 0) {
        this.$emit("editInfo", { type: "1" }); // 关闭窗口
        return;
      }
      let formValid = true;
      this.submitClicked = false;
      // if(this.companyform.name.length == 0){
      //   ElMessage.warning('请输入企业名称')
      //   return
      // }
      let canNull = []; // 可以为空的参数
      try {
        this.companyform.params = this.companyform.params.map((item) => {
          if (item.valId && item.val?.length === 0) {
            // 选项ID存在但val未填
            item.val = item.valId;
          }
          return item;
        });
        this.companyform.params.forEach((item, index) => {
          if (item.type === "industrys") {
            item.val = this.$refs.industrys.getResult()?.map((item) => item[1]); // 多选行业
            if (item.val.length === 0 && item.notNull === 1) {
              throw new Error("请选择" + item.name + "!");
            }
          }
          if (
            ["企业地址", "园区地址", "单位地址"].indexOf(item.name) !== -1 ||
            item.type === "qidaoAddress"
          ) {
            if (this.location.length === 0) {
              throw new Error(item.nullTip + "！");
            }
          } else if (
            (item.valId?.length === 0 || !item.valId) &&
            item.notNull === 1 &&
            item.selections
          ) {
            throw new Error(item.nullTip + "!");
          } else if (
            (item.val?.length === 0 || !item.val) &&
            item.notNull === 1 &&
            !item.selections
          ) {
            throw new Error(item.nullTip + "!");
          } else if (
            ["企业名称", "单位名称", "园区名称"].indexOf(item.name) !== -1
          ) {
            if (/^\d+$/.test(item.val)) {
              throw new Error(item.name + "不能为纯数字！");
            }
          } else if (item.notNull === 0) {
            // 保存可以为空的参数
            canNull.push(index);
          }
          if (index === 0 && this.showImgUpload) {
            // 判断新增选项
            if (this.staffName.length === 0) {
              throw new Error("请输入您的姓名！");
            } else if (!((this.positionId + "").length > 0)) {
              throw new Error("请选择职位！");
            } else if (!((this.departmentId + "").length > 0)) {
              throw new Error("请选择部门！");
            }
          }
        });
      } catch (error) {
        if (!error.message.startsWith("请")) {
          // 提示修改
          error.message = "请编辑" + error.message;
        }
        ElMessage.warning(error.message);
        this.submitClicked = true;
        return;
      }
      this.$refs["ruleCompany"].validate((valid) => {
        if (!valid) {
          formValid = false;
          return Promise.resolve(false);
        }
      });
      if (!formValid) {
        return false;
      }
      if (this.extraParams.length > 0) {
        for (let i = 0; i < this.extraParams.length; i++) {
          // 附加值空判断
          if (this.extraParams[i].val.length === 0) {
            ElMessage.warning(this.extraParams[i].name + "不能为空!");
            return;
          }
        }
        this.extraParams.map((item) => {
          // 附加地址转换JSON
          if (item.type === "qidaoAddress") {
            let val = {
              addressAreaId: parseInt(this.extraLocation[2]),
              addressCityId: parseInt(this.extraLocation[1]),
              addressProvinceId: parseInt(this.extraLocation[0]),
              addressAreaName:
                this.$refs[`AddrExtra${item.id}`].getCheckedNodes()[0]
                  .pathLabels[2],
              addressCityName:
                this.$refs[`AddrExtra${item.id}`].getCheckedNodes()[0]
                  .pathLabels[1],
              addressProvinceName:
                this.$refs[`AddrExtra${item.id}`].getCheckedNodes()[0]
                  .pathLabels[0],
            };
            let temp = {
              parameterId: item.id,
              val: JSON.stringify(val),
            };
            item.val = temp;
          }
          return item;
        });
        // extraParams = this.extraParams.map((item)=>{  // 参数格式设定
        //   return {
        //     "parameterId": item.id,
        //     "val": item.val
        //   }
        // })
      }
      this.companyform.addressAreaId = this.location[2]
        ? parseInt(this.location[2])
        : "";
      this.companyform.addressCityId = this.location[1]
        ? parseInt(this.location[1])
        : "";
      this.companyform.addressProvinceId = this.location[0]
        ? parseInt(this.location[0])
        : "";
      this.companyform.addressAreaName = this.$refs[
        "cascaderAddr"
      ].getCheckedNodes()[0]
        ? this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2]
        : "";
      this.companyform.addressCityName = this.$refs[
        "cascaderAddr"
      ].getCheckedNodes()[0]
        ? this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1]
        : "";
      this.companyform.addressProvinceName = this.$refs[
        "cascaderAddr"
      ].getCheckedNodes()[0]
        ? this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0]
        : "";
      let params = this.companyform.params.map((item) => {
        if (
          ["企业地址", "园区地址", "单位地址", "地址"].indexOf(item.name) !== -1
        ) {
          item.val = {
            addressAreaId: this.companyform.addressAreaId,
            addressCityId: this.companyform.addressCityId,
            addressProvinceId: this.companyform.addressProvinceId,
            addressAreaName: this.companyform.addressAreaName,
            addressCityName: this.companyform.addressCityName,
            addressProvinceName: this.companyform.addressProvinceName,
            addressDetail:
              this.companyform.addressProvinceName +
              "-" +
              this.companyform.addressCityName +
              "-" +
              this.companyform.addressAreaName, // 详细地址字段
          };
          item.val = JSON.stringify(item.val);
        } else if (
          item.name === "企业研究机构" ||
          item.name === "技术人员研究方向"
        ) {
          item.val = item.valId + "";
        } else if (item.type === "industrys") {
          item.val = this.$refs.industrys
            .getResult()
            ?.map((item) => item[1])
            .join(",");
        } else if (
          ["企业名称", "单位名称", "园区名称", "姓名"].indexOf(item.name) !== -1
        ) {
          this.companyform.name = item.val;
        } else if (item.selections) {
          item.val = item.valId;
        }
        return {
          parameterId: item.parameterId,
          val:
            typeof item.val === "string" ? item.val : JSON.stringify(item.val),
        };
      });
      this.loading = true;
      let images = [];
      if (this.loadedImgs.length > 0) {
        this.loadedImgs.forEach((item) => {
          let start = item.url.indexOf(staticPath);
          let temp = item.url.slice(start + staticPath.length, item.length);
          images.push(temp);
        });
      }
      this.licenseImgs = [...images, ...this.licenseImgs];
      this.companyform.license = this.licenseImgs;
      this.loading = false;
      if (this.companyform.license.length === 0) {
        ElMessage.warning("请上传企业认证信息!");
        return;
      }
      this.btnDisabled = true;
      try {
        if (this.organizationId === -1) {
          ElMessage.warning("无组织ID");
          return;
        }
        // let industryIds = this.companyform.industryList.map((item)=>{
        //   return item.id
        // })
        canNull.reverse(); // 反转防止 修改数组后变短
        canNull.forEach((item) => {
          // 删去为空的参数
          if (!params[item].val) params.splice(item, 1);
        });
        let param = {
          companyPositionId: this.positionId,
          companyDepartmentId: this.departmentId,
          companyStaffName: this.staffName,
          addressAreaId: this.companyform.addressAreaId,
          addressAreaName: this.companyform.addressAreaName,
          addressCityId: this.companyform.addressCityId,
          addressCityName: this.companyform.addressCityName,
          addressProvinceId: this.companyform.addressProvinceId,
          addressProvinceName: this.companyform.addressProvinceName,
          id: this.organizationId,
          industryIds: this.companyform.industryIds, // 1.7 多选
          productName: "",
          scientificResearchCount: "",
          researchFund: "",
          license: this.companyform.license,
          researchInstitutions: "",
          // "label": this.companyform.label,
          memberId: this.companyform.memberId,
          params: params, // 附加参数
          name: this.companyform.name, // 新增公司名称
          version: this.templateVersion, // 版本号
          summary: "",
        };
        // console.log('tes final params:',param);
        this.loading = true;
        await editOrg(param); // 上传新的公司信息
        this.loading = false;
        this.btnDisabled = false;
        let that = this;
        this.ElMessage.success("修改成功！");
        setTimeout(() => {
          that.$emit("editInfo", { type: "1" }); // 关闭窗口
        }, 1000);
      } catch (e) {
        this.btnDisabled = false;
        this.loading = false;
        // this.ElMessage.warning(e.message)
      }
    },
  },
};
</script>
  
  <style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
}

.contact-container {
  display: flex;
  flex-direction: column;
  .top-bg {
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .selection {
      width: 418px;
      height: 380px;
      background: white;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

.selection-top {
  background: $--color-theme;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-top p {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.selection-content {
  padding: 40px 0;
  width: 100%;
  height: 340px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.selection-item {
  width: 360px;
  height: 77px;
  background: #f06418;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgb(208, 207, 207);
}

.bg-blue {
  background: #25a5f4;
}

.selection-item p {
  color: white;
  font-size: 28px;
}
/* 科研人员表单 */
.option {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #999999;
  background: #f7f8f9;
  padding: 3px 20px;
  border-radius: 21px;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 12px;
  cursor: not-allowed;
}
.school {
  width: 1000px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
}
.school-content {
  width: 100%;
  padding: 15px 50px 15px 50px;
  box-sizing: border-box;
  .item-form {
    margin-bottom: 20px;
    .tip {
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
    }
    .tip-small {
      font-size: 14px;
      margin: 10px 0;
    }
    .company-summary {
      padding-top: 20px;
    }
    .disabled {
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #999999;
      padding-bottom: 10px;
    }
  }
  p {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .empty-remind {
    font-size: 14px;
    line-height: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #e71b00;
    bottom: 4px;
  }
}
::v-deep .el-loading-mask {
  z-index: 600 !important;
}
::v-deep .el-input__inner {
  background: #ffffff !important;
  width: 100% !important;
}
:deep() .el-input__count-inner {
  background: #ffffff !important;
}
.input-css {
  /*background: #F3F3F3 !important;*/
  width: 100% !important;
}
.window-btn-company {
  padding-top: 40px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .cancel {
    width: 200px;
    height: 50px;
    background: #999999;
    color: #ffffff;
    border-radius: 4px;
  }
  .cancel:hover {
    color: #ffffff;
  }
  .confirm {
    width: 200px;
    height: 50px;
    color: #ffffff;
    background: $--color-theme;
    border-radius: 4px;
  }
  .confirm:hover {
    color: #ffffff;
  }
}
.window-btn {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
  p {
    margin-bottom: 0;
  }
}

.window-btn div {
  width: 288px;
  height: 68px;
  background: #f06418;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-text {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}

/* 企业 */
.company {
  width: 1000px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
}

.havelab-div {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background: #f3f3f3;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.width360 {
  width: 360px;
}
.icon-delete {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 3px 10px 0 10px;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}
</style>
  