import {login, memberDetails, refreshToken} from '@/api/user'
import { getUserInfo, removeUserInfo, setUserInfo} from '@/utils/auth'
// import {setToken, removeToken, getUserInfo, removeUserInfo, setUserInfo} from '@/utils/auth'
import router, {resetRouter} from '@/router'
import {kqdPath,} from "@/utils/consts";

let {
  accessToken,
  avatar,
  name,
  id,
  industryId, // 多选，改用数组类型
  // industry,
  imEasemobUsername,
  imPassword,
  organizationName,
  organizationId,
  organizationType,
  level,
  vipEndTime,
  vipStartTime,
  loginTime // 登录时间
} = getUserInfo()

const state = {
  timChange: false, // 修改获取Tim信息
  visibility: true, // 页面是否展示
  reloadUser: false,  // 手动更新数字科创
  openCreateLive: false, // 打开导航栏路演创建
  updateNavbar: false, // 手动更新导航栏
  navbarBread: [],  // 导航栏面包屑
  showDarkTheme: false, // 展示黑白颜色
  showNewYearTheme: false,  // 展示新年主题
  showTop: true, // 是否展示完整的navbar
  showMiddle: true, // 是否展示中间的navbar
  location: '未知', // 定位
  closed: false,
  name,
  avatar,
  id,
  industryId, // 多选，改用数组类型
  // industry,
  imEasemobUsername,
  imPassword,
  organizationId,
  organizationName,
  organizationType,
  level,
  isVip: false,
  vipEndTime,
  vipStartTime,
  token: accessToken,
  introduction: '',
  roles: ['admin'],
  phone: "",
  belong: '',
  position: '',
  positionId: -1,
  educationName: '',
  enterpriseType: '', // 企业类型
  educationId: -1,
  loginTime,
  showNavbar: true, // 是否显示Navbar
  editingUserInfo: false, // 用于通知用户信息更新
}

const mutations = {
  SET_TIM_CHANGE:(state)=>{
    // 修改获取Tim状态
    state.timChange = !state.timChange
  },
  SET_DOCUMENT_VISIBILITY:(state,bool)=>{
    // 设置页面可见
    state.visibility = bool
  },
  OPEN_CREATE_LIVE:(state)=>{
    state.openCreateLive = !state.openCreateLive
  },
  SET_RELOAD_USER:(state)=>{
    // 手动更新用户信息
    state.reloadUser = !state.reloadUser
  },
  SET_UPDATE_NAVBAR:(state)=>{
    // 手动更新导航栏
    state.updateNavbar = !state.updateNavbar
  },
  SET_NAVBAR_BREAD:(state,bread)=>{
    // 设置面包屑
    state.navbarBread = bread
  },
  SET_NEW_YEAR_THEME:(state,bool)=>{
    state.showNewYearTheme = bool
  },
  SET_DARK_THEME:(state,bool)=>{
    // 设置暗色主题
    state.showDarkTheme = bool
  },
  SET_ENTERPRISE_TYPE:(state,type)=>{ // 设置企业类型
    state.enterpriseType = type
  },
  SET_SHOW_MIDDLE:(state,bool)=>{  // showMiddle
    state.showMiddle = bool
  },
  SET_SHOW_TOP:(state,bool)=>{  // 设置showTop
    state.showTop = bool
  },
  SET_LOCATION:(state,location) => state.location = location,
  SET_ISVIP: (state, val) => state.isVip = val,
  SET_CLOSED_TRUE:(state)=>{
    state.closed = true
  },
  SET_CLOSED_FALSE:(state)=>{
    state.closed = false
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    state.loginTime = new Date().valueOf()
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_EDITING: (state)=>{
    state.editingUserInfo = !state.editingUserInfo
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_INDUSTRY: (state, industryIds) => {
    state.industryId = industryIds
  },
  SET_ORGANIZATION_ID:(state,id)=>{
    state.organizationId = id
  },
  SET_ORGANIZATION: (state, org) => {
    state.organizationName = org.name
    state.organizationId = org.id
    state.organizationType = org.type
  },
  SET_IMUSERNAME: (state, username) => {
    state.imEasemobUsername = username
  },
  SET_IMPASSWORD: (state, password) => {
    state.imPassword = password
  },
  SET_VIP_INFO: (state, vipInfo) => {
    state.level = vipInfo.level || state.level
    state.vipEndTime = vipInfo.vipEndTime || state.vipEndTime
    state.vipStartTime = vipInfo.vipStartTime || state.vipStartTime
    if (state.vipEndTime && state.vipStartTime) {
      let now = new Date()
      state.vipStartTime = [now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0]
    }
  },
  SET_SHOW_NAVBAR:(state, status)=>{
    state.showNavbar = status
  }
}

const actions = {
  setNavbarBread({commit},bread){
    // 设置面包屑
    /*
      面包屑格式：
      {
        path: '/',
        name: '首页',
      }
    */
    commit('SET_NAVBAR_BREAD',bread)
  },
  setUpdateNavbar({commit}){
    // 手动更新导航栏
    commit('SET_UPDATE_NAVBAR')
  },
  setNewYearTheme({commit},bool){
    // 设置新年主题
    commit('SET_NEW_YEAR_THEME',bool)
  },
  setDarkTheme({commit},bool){
    // 设置暗色主题
    commit('SET_DARK_THEME',bool)
  },
  setNavbar({commit},status){
    commit('SET_SHOW_NAVBAR',status)
  },
  changeName({commit},name){
    commit('SET_NAME',name)
  },
  changeAvatar({commit},avatar){
    commit('SET_AVATAR',avatar)
  },
  changeVipStatus({commit}, val) {
    commit('SET_ISVIP', val)
  },
  loginClosed({commit}){
    commit('SET_CLOSED_TRUE')
  },
  logoutClosed({commit}){
    commit('SET_CLOSED_FALSE')
  },
  // 第三方登录
  async otherLogin({commit},res){
    return new Promise((resolve, reject) => {
      try {
        setUserInfo({
          "id": res.data.memberInfo.id,
          "accessToken": res.data.accessToken,
          "name": res.data.memberInfo.name,
          "avatar": res.data.memberInfo.headImage,
          // "industryId": res.data.memberInfo.industryId,     // 此处返回值为空
          "industryId": res.data.memberInfo.memberIndustry,    // 应该使用此处保存用户行业的ID(多选行业)，数组类型
          // "industry": res.data.memberInfo.industry,
          // "imEasemobUsername": res.data.memberInfo.imEasemobUsername,
          "imEasemobUsername": res.data.memberInfo.id,
          "imPassword": res.data.memberInfo.password,
          "organizationId": res.data.memberInfo.organizationId,
          "organizationName": res.data.memberInfo.organizationName,
          organizationType: res.data.memberInfo.organizationType,
          level: res.data.memberInfo.level,
          vipStartTime: res.data.memberInfo.vipStartTime,
          vipEndTime: res.data.memberInfo.vipEndTime,
          loginTime: (new Date()).valueOf()
        })
        localStorage.setItem("userInfos", JSON.stringify(res.data)) // 此处行业多选与上方一样
        commit('SET_TOKEN', res.data.accessToken)
        commit('SET_ID', res.data.memberInfo.id)
        commit('SET_NAME', res.data.memberInfo.name)
        commit('SET_AVATAR', res.data.memberInfo.headImage)
        commit('SET_INDUSTRY', res.data.memberInfo.memberIndustry)
        commit('SET_ORGANIZATION', {id: res.data.memberInfo.organizationId, name: res.data.memberInfo.organizationName, type: res.data.memberInfo.organizationType})
        commit('SET_IMUSERNAME', res.data.memberInfo.id)
        commit('SET_IMPASSWORD', res.data.memberInfo.password)
        commit('SET_VIP_INFO', {
          level: res.data.memberInfo.level,
          vipStartTime: res.data.memberInfo.vipStartTime,
          vipEndTime: res.data.memberInfo.vipEndTime
        })
        // setToken(res.data.accessToken)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  resLogin({commit},params){
    if(params.res?.code !== 'Sx200') return
    const res = params.res
    setUserInfo({
      "id": res.data.memberInfo.id,
      "accessToken": res.data.accessToken,
      "name": res.data.memberInfo.name,
      "avatar": res.data.memberInfo.headImage,
      // "industryId": res.data.memberInfo.industryId,     // 此处返回值为空
      "industryId": res.data.memberInfo.memberIndustry,    // 应该使用此处保存用户行业的ID(多选行业)，数组类型
      // "industry": res.data.memberInfo.industry,
      // "imEasemobUsername": res.data.memberInfo.imEasemobUsername,
      "imEasemobUsername": res.data.memberInfo.id,
      "imPassword": res.data.memberInfo.password,
      "organizationId": res.data.memberInfo.organizationId,
      "organizationName": res.data.memberInfo.organizationName,
      organizationType: res.data.memberInfo.organizationType,
      level: res.data.memberInfo.level,
      vipStartTime: res.data.memberInfo.vipStartTime,
      vipEndTime: res.data.memberInfo.vipEndTime,
      loginTime: (new Date()).valueOf()
    })
    localStorage.setItem("userInfos", JSON.stringify(res.data)) // 此处行业多选与上方一样
    commit('SET_TOKEN', res.data.accessToken)
    commit('SET_ID', res.data.memberInfo.id)
    commit('SET_NAME', res.data.memberInfo.name)
    commit('SET_AVATAR', res.data.memberInfo.headImage)
    commit('SET_INDUSTRY', res.data.memberInfo.memberIndustry)
    commit('SET_ORGANIZATION', {id: res.data.memberInfo.organizationId, name: res.data.memberInfo.organizationName, type: res.data.memberInfo.organizationType})
    commit('SET_IMUSERNAME', res.data.memberInfo.id)
    commit('SET_IMPASSWORD', res.data.memberInfo.password)
    commit('SET_VIP_INFO', {
      level: res.data.memberInfo.level,
      vipStartTime: res.data.memberInfo.vipStartTime,
      vipEndTime: res.data.memberInfo.vipEndTime
    })
    commit('SET_RELOAD_USER')
  },
  // user login
  login({commit}, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        setUserInfo({
          "id": res.data.memberInfo.id,
          "accessToken": res.data.accessToken,
          "name": res.data.memberInfo.name,
          "avatar": res.data.memberInfo.headImage,
          // "industryId": res.data.memberInfo.industryId,     // 此处返回值为空
          "industryId": res.data.memberInfo.memberIndustry,    // 应该使用此处保存用户行业的ID(多选行业)，数组类型
          // "industry": res.data.memberInfo.industry,
          // "imEasemobUsername": res.data.memberInfo.imEasemobUsername,
          "imEasemobUsername": res.data.memberInfo.id,
          "imPassword": res.data.memberInfo.password,
          "organizationId": res.data.memberInfo.organizationId,
          "organizationName": res.data.memberInfo.organizationName,
          organizationType: res.data.memberInfo.organizationType,
          level: res.data.memberInfo.level,
          vipStartTime: res.data.memberInfo.vipStartTime,
          vipEndTime: res.data.memberInfo.vipEndTime,
          loginTime: (new Date()).valueOf()
        })
        localStorage.setItem("userInfos", JSON.stringify(res.data)) // 此处行业多选与上方一样
        commit('SET_TOKEN', res.data.accessToken)
        commit('SET_ID', res.data.memberInfo.id)
        commit('SET_NAME', res.data.memberInfo.name)
        commit('SET_AVATAR', res.data.memberInfo.headImage)
        commit('SET_INDUSTRY', res.data.memberInfo.memberIndustry)
        commit('SET_ORGANIZATION', {id: res.data.memberInfo.organizationId, name: res.data.memberInfo.organizationName, type: res.data.memberInfo.organizationType})
        commit('SET_IMUSERNAME', res.data.memberInfo.id)
        commit('SET_IMPASSWORD', res.data.memberInfo.password)
        commit('SET_VIP_INFO', {
          level: res.data.memberInfo.level,
          vipStartTime: res.data.memberInfo.vipStartTime,
          vipEndTime: res.data.memberInfo.vipEndTime
        })
        commit('SET_RELOAD_USER')
        // setToken(res.data.accessToken)
        resolve()
      }).catch(err => {
        reject(err)
      })

    })
  },

  cookieLogin({commit},res){
    setUserInfo({
      "id": res.data.memberInfo.id,
      "accessToken": res.data.accessToken,
      "name": res.data.memberInfo.name,
      "avatar": res.data.memberInfo.headImage,
      // "industryId": res.data.memberInfo.industryId,     // 此处返回值为空
      "industryId": res.data.memberInfo.memberIndustry,    // 应该使用此处保存用户行业的ID(多选行业)，数组类型
      // "industry": res.data.memberInfo.industry,
      // "imEasemobUsername": res.data.memberInfo.imEasemobUsername,
      "imEasemobUsername": res.data.memberInfo.id,
      "imPassword": res.data.memberInfo.password,
      "organizationId": res.data.memberInfo.organizationId,
      "organizationName": res.data.memberInfo.organizationName,
      organizationType: res.data.memberInfo.organizationType,
      level: res.data.memberInfo.level,
      vipStartTime: res.data.memberInfo.vipStartTime,
      vipEndTime: res.data.memberInfo.vipEndTime,
      loginTime: (new Date()).valueOf()
    })
    localStorage.setItem("userInfos", JSON.stringify(res.data)) // 此处行业多选与上方一样
    commit('SET_TOKEN', res.data.accessToken)
    commit('SET_ID', res.data.memberInfo.id)
    commit('SET_NAME', res.data.memberInfo.name)
    commit('SET_AVATAR', res.data.memberInfo.headImage)
    commit('SET_INDUSTRY', res.data.memberInfo.memberIndustry)
    commit('SET_ORGANIZATION', {id: res.data.memberInfo.organizationId, name: res.data.memberInfo.organizationName, type: res.data.memberInfo.organizationType})
    commit('SET_IMUSERNAME', res.data.memberInfo.id)
    commit('SET_IMPASSWORD', res.data.memberInfo.password)
    commit('SET_VIP_INFO', {
      level: res.data.memberInfo.level,
      vipStartTime: res.data.memberInfo.vipStartTime,
      vipEndTime: res.data.memberInfo.vipEndTime
    })
    commit('SET_RELOAD_USER')
  },
  regainToken({commit},token) {
    let param = {
      "refreshToken": token
    }
    return new Promise((resolve, reject) => {
      refreshToken(param).then(res => {
        commit('SET_TOKEN', res.data)
        let userInfos = JSON.parse(localStorage.getItem('userInfos'))
        userInfos.accessToken = res.data
        localStorage.setItem("userInfos", JSON.stringify(userInfos))
        resolve()
      }).catch(err => {
        console.error('token过期,重新获取错误:',err)
        commit('SET_TOKEN', '')
        commit('SET_ID', -1)
        removeUserInfo()
        reject(err)
      })
    })
  },

  updateVipInfo({commit, state}) {
    memberDetails(state.id).then(res => {
      commit('SET_ISVIP', res.data.isVip)
      commit('SET_VIP_INFO', {
        level: res.data.level,
        vipStartTime: null,
        vipEndTime: res.data.vipEndTime
      })
      let userInfo = getUserInfo()
      userInfo.level = res.data.level
      userInfo.vipStartTime = res.data.vipStartTime
      userInfo.vipEndTime = res.data.vipEndTime
      userInfo.organizationType = res.data.organizationType
      userInfo.organizationVipEndTime = res.data.organizationVipEndTime
      userInfo.isVip = res.data.isVip
      setUserInfo(userInfo)
    }).catch(()=>{
      setTimeout(()=>{
        location.replace(kqdPath + router.currentRoute._rawValue.fullPath) // 当返回undefined 错误
      },2000)
    })
  },
  setIndustry({commit}, industryIds) {  // 存入行业(多选)
    return new Promise((resolve) => {
      commit('SET_INDUSTRY', industryIds)
      let userInfo = getUserInfo()
      userInfo.industryId = industryIds
      setUserInfo(userInfo)
      resolve()
    })
  },
  updateToken({commit},industryIds) {
    return new Promise((resolve => {
      commit('SET_INDUSTRY', industryIds)
      let userInfo = getUserInfo()
      userInfo.industryId = industryIds
      setUserInfo(userInfo)
      resolve()
    }))
  },
  editUserInfo({commit}, newProfile) {
    let userInfo = getUserInfo()

    userInfo.name = newProfile.name
    commit('SET_NAME', newProfile.name)

    userInfo.avatar = newProfile.headImage
    commit('SET_AVATAR', newProfile.headImage)
    commit('SET_EDITING')
    setUserInfo(userInfo)
  },
	editUserName({commit}, newName) {
		let userInfo = getUserInfo()

		userInfo.name = newName
		commit('SET_NAME', newName)

		setUserInfo(userInfo)
	},
  editEnterpriseId({commit},id){  // 设置组织ID
    let userInfo = getUserInfo()
    let userInfos = JSON.parse(localStorage.getItem('userInfos'))
		userInfo.organizationId = id
    userInfos.memberInfo.organizationId = id
    localStorage.setItem('userInfos',JSON.stringify(userInfos)) // 更改组织类型
    commit('SET_ORGANIZATION_ID', id)
    setUserInfo(userInfo)
  },
	editOrganization({commit}, org) {
		let userInfo = getUserInfo()
		userInfo.organizationType = org
    let userInfos = JSON.parse(localStorage.getItem('userInfos'))
    userInfos.memberInfo.organizationType = org
    localStorage.setItem('userInfos',JSON.stringify(userInfos)) // 更改组织类型
    // 新建立组织时调用的方法，id不能为null，暂定为1
		commit('SET_ORGANIZATION', {id: 1, name: userInfo.organizationName, type: org})
		setUserInfo(userInfo)
	},
  logout({commit}) {
    return new Promise((resolve) => {
      commit('SET_AVATAR','')
      commit('SET_TOKEN', '')
      commit('SET_ID', -1)
      commit('SET_ORGANIZATION',{id: null,name: null,type:null})
      localStorage.setItem('messageCounter', 0)
      removeUserInfo()
      // removeToken()
      resolve(null)
    })
  },

  // dynamically modify permissions
  async changeRoles({commit, dispatch}, role) {
    const token = role + '-token'
    commit('SET_TOKEN', token)
    // setToken(token)

    const {roles} = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {root: true})
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
