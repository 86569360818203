import {post} from "@/utils/request";
import store from '@/store'
import { TanSite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";

// 获取邀请短链接
export function getInviteLink(type){
    let id = store.getters.id
    if(TanSite) id = getTanId()
    const params = {
        type,
        memberId: id,
    }
    return post('/invite/generatorShortUrl',params)
}



// 获取随机推荐的庄稼
export function randomExpert(keyword,id){
    const param = {
        "keyword": keyword?.length >= 100 ? keyword.slice(0,100) : keyword,
        "memberId": id
    }
    return post("/lab/randomExpert",param)
}

// 查看公司顶部信息（名字、人数、管理者）
export function getEnterpriseTopInfo(id,memberId){
    const param = {
        "enterpriseId": id,
        "memberId": memberId
    }
    return post("/enterprise/enterpriseTopInfo",param)
}

// 发送邮件的接口
export function sendCompanyEmail(param,type = 1){
    let id = store.getters.id
    if(TanSite) id = getTanId()
    const params = {
        recipientName: param.recipientName.length > 100 ? param.recipientName.slice(0,100) : param.recipientName,   // 收件人姓名
        type: type,
        recipient: param.id,
        memberId: id,
        images: param.images,
        theme: param.theme,
        content: param.content
    }
    return post('/organization/sendCompanyEmail', params)
}

export function getOrganizationMembers(id) {
    return post('/organization/findOrganizationMembers', {id})
}

// 获取公司动态分类分页查询
export function getSeletEnterprise(enterpriseId,type) {
    let params = {
        "limit": 20,
        "offset": 1,
        "organizationId": enterpriseId,
        "type": type
      }
    return post('/enterprise/getDynamicTypeByType', params)
}


// 获取组织机构类型模板
export function getOrganizationTemplate(typeId) {
    return post('/organization/template', {typeId})
}
// 获取组织机构类型
export function getOrganizationTypes(type) {
    let params = {
        "memberId": store.getters.id,
        "type": type
    }
    return post('/organization/type', params)
}

// 获取企业完整信息
export function getEnterpriseInfo(id){
    return post('/enterprise/enterpriseSpace',{id})
}
// 获取企业信息
export function getEnterpriseById(id){
    return post('/enterprise/findByOrganizationId',{id})
}

// 根据人员ID 获取实验室信息
export function getLabInfoByMemberId(id){
    return post('/organization/new/findByMemberId',id)
}

// 获取实验室信息(新) 
export function getLabInfoNew(id){
    return post('/lab/findOrganizationSpace',id)
}

// 获取实验室（小）信息 
export function getLabInfoSmall(id){
    return post('/organization/findByOrganizationId',id)
}

// 获取实验室（大）信息
export function getLabInfo(id){
    return post('/organization/new/findByOrganizationId',id)
}
// new 查询归并实验室资料
export function newBigLabInfo(id){
    return post('/lab/findBigByOrganizationId',id)
}

// 获取企业空间信息
export function getEnterpriseSpace (id){
    return post('/organization/enterprise/findByOrganizationId',{id})
}
// 查询企业下所有成员
export function findEnterpriseMember (id){
    return post('/organization/findEnterpriseMember',{
        "organizationId": id,
        "memberId": store.getters.id,
        "offset": 1,
        "limit": 20
    })
}

export function organizationSpace(id) {
    return post('/organization/findOrganizationSpace', {id})
}

// new 获取实验室空间
export function newLabSpaceInfo(id){
    return post('/lab/findOrganizationSpace',{id})
}
