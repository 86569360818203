import {post} from "@/utils/request";
import store from '@/store'
import { ElMessage } from "element-plus";
import { G60site,TanSite } from "@/router/index";

// 查看聊天权限
export function checkMemberChat(toId,fromId){
  return post('/member/checkMemberChatAuthorityToBoolean',{
    friendMemberId: toId,
    memberId: fromId
  })
}
// 未读系统消息数目
export function unreadSystemNum(memberId){
  if(G60site || TanSite) return {data: 0}
  return post('/msg/msgRecord/selectNotReadMsgCount',{id:memberId})
}

// 获取所有系统消息内容
export function getAllSystemMessage(params){
  const param = {
    "limit": 5,
    "memberId": params.memberId,
    "offset": params.offset
  }
  return post('/msg/msgRecord/getAllNoticeMsgRecordPage',param)
}
// 清除系统消息记录
export function clearSystemMessageById(memberId){
  return post('/msg/msgRecord/clearReadMsgRecordByMemberId',{id:memberId})
}

export function friendsList() {
  return post('/easemob/api/user/getFriendList', {
    "memberId": store.getters.id
  })
}


export function addFriend(friendMemberId, serverId) {
  if(serverId){
    return post('/easemob/api/user/addFriend', {
      "demandId": serverId,        //新版用demandId 一键沟通时必传; friendMemberId 所发布的服务需求ID
      "friendMemberId": friendMemberId,
      "memberId": store.getters.id,
      // "serverId": serverId  一键沟通时必传; friendMemberId 所发布的服务需求ID
    })
  }
  else{
    return post('/easemob/api/user/addFriend', {
      "friendMemberId": friendMemberId,
      "memberId": store.getters.id,
      // "serverId": serverId  一键沟通时必传; friendMemberId 所发布的服务需求ID
    })
  }
  
}

export function friendStatus(friendId) {
  return post('/easemob/api/user/statusOne', { username: friendId })
}

export function uploadImage(image) {
  if(image.size > 2097152){      // 1m == 1048576   < 2M
    ElMessage.warning('图片太大，请选择其他图片！')
    return false
  }
  let fd = new FormData()
  fd.append("file", image)
  return post(
    '/easemob/api/file/upload',
    fd
  )
}