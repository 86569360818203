import { TanWhitePage } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { getTanLocalInfo,setTanLocalInfo,removeTanLocalInfo,getTanToken,getTanRefreshToken,getTanId } from "./login";
import { getCookie,setCookie } from "@/utils/Cookies/index";
import { memberDetails } from "@/api/user";
import { getTanMemberInfo } from "@/api/Tan/member";
import store from '@/store';


export const TanPermission = (to,from,next) => {
  if(getTanLocalInfo()) return  // 已经登录
  else if(TanWhitePage.includes(to.path)) return // Tan 白名单
  setTimeout(()=>{
    store.dispatch('Tan/setLoginShow',true) // 登录弹窗
  },1000)
  next({
    path: '/',
    query:{
    }
  })
}

export const judgeTanCookie = ()=>{
  if(!getCookie('KqdInfo') && getTanLocalInfo()){
    // 本地登陆了却没有cookie
    const info = getTanLocalInfo()
    const localNew = {
      status: '1',
      accessToken: getTanToken(),
      memberInfo: {
        id: info.id,
      },
      refreshToken: getTanRefreshToken()
    }
    setCookie('KqdInfo',localNew)
  }
  else if(getCookie('KqdInfo') && !getTanLocalInfo()){
    // 有cookie但无本地缓存
    let cookie = JSON.parse(getCookie('KqdInfo'))
    if(cookie.status + '' === '0'){
      // 退出登录状态 退出登录清除缓存
      removeTanLocalInfo()
      store.dispatch('Tan/setLoginChange','cookie') // 通知获取状态
    }
    else{
      setTanLocalInfo(cookie) // 存入 token
      memberDetails(cookie.memberInfo.id).then(res=>{
        if(res.code === "Sx200"){
          cookie.memberInfo = res.data
          setTanLocalInfo(cookie) // 存入信息
        }
      })
    }
  }
  else if(getCookie('KqdInfo') && getTanLocalInfo()){
    let cookie = JSON.parse(getCookie('KqdInfo'))
    if(cookie.status + '' === '0'){
      // 退出登录状态 退出登录清除缓存
      removeTanLocalInfo()
      store.dispatch('Tan/setLoginChange','cookie') // 通知获取状态
    }
    else if(cookie.memberInfo.id + '' !== getTanLocalInfo().memberInfo.id + ''){
      // 判断是否更换了账号以cookie账号为准
      setTanLocalInfo(cookie) // 存入新账号的token
      memberDetails(cookie.memberInfo.id).then(res=>{
        if(res.code === "Sx200"){
          cookie.memberInfo = res.data
          setTanLocalInfo(cookie) // 存入信息
          store.dispatch('Tan/setLoginChange','cookie') // 通知获取状态
          location.reload() // 刷新页面
        }
      })
    }
  }
}

export const TanChangeVisibility = (route,value,router) => {
  if(value) return
  judgeTanCookie()
  const path = route.path
  const query = route.query
  if(path === '/login' && getTanLocalInfo()){
    if(query.redirect){
      window.location.href = this.$route.query.redirect
    }
    else{
      router.push({
        path: '/'
      })
    }
  }
  else if(!TanWhitePage.includes(path) && !getTanLocalInfo()){
    ElMessage.warning('您尚未登录!')
    router.push({
      path: '/login',
      query:{
        redirect: window.location.href
      }
    })
  }
  getTanMemberInfo(getTanId())
}