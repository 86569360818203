import {post} from "@/utils/request";
import store from '@/store'
import { TanSite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";

// 获取签名
export function getUserSig (param) { 
  let { account } = param
  return post('/tencent/im/account/genUserSig', { account,expire: 30 * 24 * 60 * 60 })
}

// 拉取最近联系人-会话列表
export function getlistLatelyContact () { 
  let id = store.getters.id
  if(TanSite) id = getTanId()
  return post('/log/listLatelyContactsOrByTime', { memberId: id })
}

// 聊天详情
export function getListLatelyMsgRecord ({toMemberId, fromMemberId, offset, limit}) { 
  return post('/log/listLatelyMsgRecord ', { 
    memberId: store.getters.id,
    limit,
    offset,
    toMemberId,
    fromMemberId
  })
}

// 腾讯获取好友列表中每个好友的详细信息
export function getFriendDetailInfo (memberId) { 
  return post('/tencent/im/account/getFriendDetailInfo', { memberId })
}

// 查看用户是否在线
export function statusOne (memberId) { 
  return post('/tencent/im/account/statusOne', { memberId })
}

// 腾讯im添加好友
// friendMemberId-要添加的好友会员id, memberId-会员id, serverId-一键沟通时必传
export function addFriend(friendMemberId, serverId) {
  if(serverId){
    return post('/tencent/im/account/addFriend', {
      "demandId": serverId,        //新版用demandId 一键沟通时必传; friendMemberId 所发布的服务需求ID
      "friendMemberId": friendMemberId,
      "memberId": store.getters.id,
      // "serverId": serverId  一键沟通时必传; friendMemberId 所发布的服务需求ID
    })
  }
  else{
    return post('/tencent/im/account/addFriend', {
      "friendMemberId": friendMemberId,
      "memberId": store.getters.id
    })
  }
}
