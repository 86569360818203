import {post, service, del} from "@/utils/request";
import store from '@/store'
import { TanSite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";


// 获取动态对应的专家
export function dynamicExperts(dynamicId,limit,offset){
	const params = {
		"keyword": dynamicId,
		"limit": limit,
		"offset": offset,
	}
	return post('/dynamic/getExpertListByDynamicId',params)
}

// 获取我的收藏
export function myFavor(page, pageSize) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
    return post('/dynamic/getMyFavor', {
        "limit": pageSize,
        "memberId": id,
        "offset": page
    })
}

// 获取我的点赞列表
export function getMyAgree(page, pageSize) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
	return post('/dynamic/getMyAgree', {
			"limit": pageSize,
			"memberId": id,
			"offset": page
	})
}

// 获取我的评论列表
export function getMyComment(page, pageSize) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
	return post('/dynamic/getMyComment', {
			"limit": pageSize,
			"memberId": id,
			"offset": page
	})
}

export function hotPublishes(page, pageSize) {
    return post('/dynamic/getHotDynamic', {
        "limit": pageSize,
        "memberId": store.getters.id,
        "offset": page
    })
}

// 删除我的动态
export function deleteDynamicById({id}) {
	let u_id = store.getters.id
	if(TanSite) u_id = getTanId()
	return del('/dynamic/deleteDynamicById', {
			"dynamic": id,
			"memberId": u_id
	})
}

export function collect(publishID) {
    return post('/dynamic/agreeDynamic', {
        "dynamicId": publishID,
        "memberId": store.getters.id
    })
}

export function cancelCollect(publishID) {
    return post('/dynamic/disagreeDynamic', {
        "dynamicId": publishID,
        "memberId": store.getters.id
    })
}

export function getHotPublishes(page, pageSize) {
    return post('/dynamic/getHotDynamic', {
        "limit": pageSize || 4,
        "memberId": store.getters.id,
        "offset": page || 1
    })
}

export function getRandomDynamic(id,limit) {
	return post('/dynamic/getRandomDynamic', {
		"limit": limit || 4,
		"memberId": store.getters.id ? store.getters.id : null,
		id
	})
}

// 动态+需求的列表
export function getlistHomepage(currentPage, pageSize, channelId) {
	return post('/homepage/listHomepage', {
		"limit": pageSize || 10,
		"memberId": store.getters.id ? store.getters.id : null,
		"offset": currentPage || 1,
		state: true,
		channelId
	})
}
// 获取所有我的动态
export function getMyDynamic(data,pageSize,currentPage) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
	return post('/dynamic/getMyDynamic', {
		"limit": pageSize || 10,
		"memberId": id,
		"offset": currentPage || 1
	})
}

export function postDynamic(data) {
	// return post('/dynamic/pushDynamic', data) // 1.2之前的动态提交
	return post('/dynamic/afterPushDynamic', data)
}

export function getDynamicDetail(dynamicid) {
	return post('/dynamic/getDynamicDetailed', {
		"dynamicId": dynamicid,
		"memberId": store.getters.id !== -1 ? store.getters.id : ''	// 未登录传入空
	})
}

export function getDynamicComment(dynamicId,limit,offset) {	// 获取动态评论
	return post('/comment/getCommentByDynamicId', {
		"dynamicId": dynamicId,
		"limit": limit,
		"memberId": store.getters.id,
		"offset": offset
	})
}

export function DynamicComment(data) {
	return post('/comment/pushComment', data)
}

export function DynamicComplaint(data) {
	return post('/dynamic/complaint/create',data)
}
// 评论的点赞与取消点赞
export function CommentAgree(commentid) {
	return post('/comment/agree', {
		"commentId": commentid,
		"memberId": store.getters.id
	})
}
export function CommentDisagree(commentid) {
	return post('/comment/disagree', {
		"commentId": commentid,
		"memberId": store.getters.id
	})
}

// 动态的点赞与取消点赞
export function DynamicAgree(dyid) {
	return post('/dynamic/agreeDynamic', {
		"dynamicId": dyid,
		"memberId": store.getters.id
	})
}
export function DynamicDisagree(dyid) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
	return post('/dynamic/disagreeDynamic', {
		"dynamicId": dyid,
		"memberId": id
	})
}

// 动态的收藏与取消收藏
export function DynamicFavor(dyid) {
	return post('/member/favor/createFavor', {
		"dynamicId": dyid,
		"memberId": store.getters.id
	})
}

export function DynamicDisfavor(dyid) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
	return service.request({
		url: '/member/favor/delete',
		data: {
			"dynamicId": dyid,
			"memberId": id
		},
		method: "DELETE"
	})
}

// 删除评论
export function deleteCommentById(commentId) {
	let id = store.getters.id
	if(TanSite) id = getTanId()
	return service.request({
		url: '/comment/deleteCommentById',
		data: {
			commentId,
			"memberId": id
		},
		method: "DELETE"
	})
}

// 获取频道
export function getChannelList(params){
  return post('/dynamic/channel/getList', params)
}


// 根据前缀查询频道
export function getChannelByPrefix(params){
  return post('/dynamic/channel/byPrefixList', params)
}
