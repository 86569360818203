import {post} from "@/utils/request";
// import store from '@/store'

//产业园区解决方案表单
export function getIndustrialPark(params) {
  return post('/activityJoin/industrialParkSolutions', params)
}

//政府解决方案表单
export function getGovernment(params) {
  return post('/activityJoin/governmentSolutions', params)
}

// 企业解决方案
export function enterpriseSolutionsApi(params) {
  return post('/activityJoin/enterpriseSolutions', params)
}


// 0731资本
export function incubatorCapital(params){
  return post('/activityJoin/capitalProjects',params)
}

// 0731资本
export function incubatorPark(params){
  return post('/activityJoin/industrialParkProject',params)
}

// 0731资本
export function incubatorProject(params){
  return post('/activityJoin/technologyProjects',params)
}


// SEI报名登录
export function SEIactivity(params){
  return post('/activityJoin/applicationActivity',params)
}

export function getActivityMember(params){
  return post('/activityJoin/activityList',params)
}

export function activityRegister(params){
  // 人才工作站： 32
  // 高级定制版咨询: 35
  // 数字科创中心联系我们: 36
  // 新个人中心联系我们: 37
  // 蓝珊瑚单向服务定制申请: 38
  // 欧阳报名表单2024年4月 39
  // 长三角硬科技发展论坛 40
  // 峰会重磅嘉宾参会确认表 41
  // 长三角硬科技发展论坛邀请函 42
  // 合伙人招募 43
  // 明日大师报名 44
  // 蓝珊瑚项目入驻 45
  // 蓝珊瑚资本入驻 46
  // 蓝珊瑚产业入驻 47
  // “明日大师”硬科技创业大赛报名表 48
  // 科创需求服务方案表 49
  // 探角预约专属客服 50
  // 探角项目合作 51
  // 探角对公转账 10001
  return post('/activityJoin/commonActivity',params)
}

export function activityMemberNum(params){
  return post('/activityJoin/activityCount',params)
}