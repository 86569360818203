<template>
  <div>
    <div class="edit-container">
      <el-card style="width: 900px" v-loading="loading" shadow="never">
        <div class="card-content" v-if="!loading">
          <el-form
            ref="form"
            :model="profile"
            label-width="150px"
            >
            <!-- :class="{ 'el-form-content': userIdentity === 1 }" -->
            <el-form-item label="用户名">
              <el-input
                v-model="profile.name"
                class="width360"
                maxlength="10"
                show-word-limit
              />
            </el-form-item>
            <el-form-item label="身份类型">
              <el-select placeholder="-" v-model="identityType" class="width360" @change="identityChange">
                <el-option
                  v-for="o in typeList"
                  :key="o.id"
                  :value="o.id"
                  :label="o.iconName"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="所属实验室" v-if="userIdentity == 0">
              <el-input
                disabled
                v-model="profile.organizationName"
                class="width360"
              />
            </el-form-item>
            <el-form-item label="所属单位" v-if="userIdentity == 0">
              <el-input disabled v-model="profile.belong" class="width360" />
            </el-form-item>
            <el-form-item label="学历" v-if="userIdentity == 0">
              <el-select
                placeholder="-"
                v-model="profile.educationId"
                class="width360"
              >
                <el-option
                  v-for="o in educationOptions"
                  :key="o.id"
                  :value="o.id"
                  :label="o.val"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职称" v-if="userIdentity == 0">
              <el-select
                placeholder="-"
                v-model="profile.positionId"
                class="width360"
              >
                <el-option
                  v-for="o in positionOptions"
                  :key="o.id"
                  :value="o.id"
                  :label="o.val"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科研方向" v-if="userIdentity == 0">
              <el-select
                class="width360"
                v-model="profile.label"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="输入单个方向后按enter键确认"
              >
              </el-select>
            </el-form-item> -->
            <!-- 1.7改为多选 -->
            <!-- <el-form-item
              label="技术可应用行业"
              class="width360"
              v-if="userIdentity == 0"
            >
              <MultiSelection
                :changing="changing"
                :selections="multiIndustry"
                :placeholder="'请选择技术可应用行业'"
                :max="10"
                :ref="'industrys'"
                :value="userIndustryIds?.join(',')"
                style="width: 340px"
              ></MultiSelection>
            </el-form-item> -->
            <!-- <el-form-item label="邮箱" v-if="userIdentity == 0">
              <el-input v-model="profile.email" class="width360" />
            </el-form-item>
            <el-form-item label="部门" v-if="userIdentity == 1 && showNewItems">
              <el-select
                placeholder="-"
                v-model="departmentId"
                class="width360"
              >
                <el-option
                  v-for="o in departmentSelections"
                  :key="o.id"
                  :value="o.id"
                  :label="o.val"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位" v-if="userIdentity == 1 && showNewItems">
              <el-select placeholder="-" v-model="positionId" class="width360">
                <el-option
                  v-for="o in positionSelections"
                  :key="o.id"
                  :value="o.id"
                  :label="o.val"
                ></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item
              label="行业"
              class="width360">
              <!-- 行业多选v-if="userIdentity == 1" -->
              <MultiSelection
                :changing="changing"
                :selections="multiIndustry"
                :placeholder="'请选择行业'"
                :max="10"
                :ref="'industrys'"
                :value="userIndustryIds?.join(',')"
                style="width: 340px"
              ></MultiSelection>
            </el-form-item>
            <el-form-item label="所在地区">
              <el-cascader style="width: 340px;" placeholder="请选择所在地区" :popper-class="'el-popDropDownPanelAddress'" v-model="newForm.addressId" clearable :props="props" @change="addressChange" ref="formAddress" @visible-change="visibleChange" @expand-change="visibleChange">
              </el-cascader>
            </el-form-item>
            <el-form-item label="单位名称">
              <el-input
                v-model="newForm.companyName"
                class="width360"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
            <el-form-item label="单位规模">
              <el-select
                placeholder="-"
                v-model="newForm.companyScale"
                class="width360"
              >
                <el-option
                  v-for="o in companyScaleOptions"
                  :key="o.id"
                  :value="o.id"
                  :label="o.val"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="担任职务职位">
              <el-input
                v-model="newForm.companyDuty"
                class="width360"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
            <!-- 1.10.14 数字科创研究方向 v-if="userIdentity == 1" -->
            <el-form-item label="研究方向" >
              <el-input
                v-model="userDirection"
                class="width360"
                placeholder="最多输入3个，以“,”分割，最长30个字符"
                maxlength="30"
              />
            </el-form-item>
            <!-- 1.8.4 新增自我介绍 -->
            <el-form-item label="自我介绍">
              <el-input
                v-model="profile.selfIntroduction"
                class="width360"
                type="textarea"
                :minlength="10"
                :maxlength="400"
                show-word-limit
                placeholder="请输入自我介绍"
                resize="none"
                rows="9"
              />
            </el-form-item>
            <el-form-item label="教育背景">
              <el-input
                v-model="newForm.educationBackground"
                class="width360"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-form>
          <div class="avatar-fix">
            <el-upload
              action="#"
              :accept="'image/*'"
              :show-file-list="false"
              :http-request="handleUpload"
              :on-change="loadJsonFromFile"
            >
              <img
                v-if="imgdisplay"
                :src="staticPath + imgdisplay"
                class="avator-center"
              />
              <i v-else class="el-icon-plus"></i>
              <div class="avatar-wrapper">
                <el-button :loading="btnDisabled" type="primary" class="avatar-button"
                  >修改头像</el-button
                >
                <el-progress
                  style="width: 200px; margin-top: 10px"
                  :text-inside="true"
                  v-if="percentage !== 100"
                  :percentage="percentage"
                ></el-progress>
              </div>
            </el-upload>
          </div>
          <!-- 底部按钮 -->
          <div class="flex-rc bottom-buttons">
            <el-button
              :loading="btnDisabled"
              class="cancel fs-18 fc-fff"
              @click="saveProfile(0)"
              >取消修改</el-button>
            <el-button
              :loading="btnDisabled"
              class="confirm fs-18 fc-fff"
              @click="saveProfile(1)"
              >保存</el-button
            >
          </div>
        </div>
        <div v-else style="height: 549px"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { updateProfile } from "@/config/WebTIMConfig"; // 对腾讯个人信息进行更新
import { mapGetters } from "vuex";
import { editProfileBasic, memberDetails } from "@/api/user"; // editProfile
import { ElMessage } from "element-plus";
import { staticPath, bucketMap } from "@/utils/consts";
import { G60site,TanSite } from "@/router/index";
import { randomFilename } from "@/utils/time";
import {
  uploadTypeImage,
  uploadTypeVideo,
  cosByUploadType,
} from "@/api/upload";
import { getSelectFatherSonByType } from "@/api/config";
import MultiSelection from "@/components/MultiSelection/MultiSelection";
import { getG60LocalInfo } from "@/utils/G60/login";
import { getTanLocalInfo } from "@/utils/Tan/login";
import { getIdentityTypes } from "@/utils/tool";
import { getprovince, getcity, getarea } from "@/api/demand";


export default {
  name: "ProfileEdit",
  computed: {
    showNewItems() {
      // 是否展示部门、职位选项
      return (
        false
        // this.enterpriseType?.indexOf("个人") === -1 &&
        // this.profile?.organizationType + "" === "1"
      );
    },
    ...mapGetters(["id", "enterpriseType"]),
  },
  components: {
    MultiSelection,
  },
  data() {
    return {
      props: {  // 懒加载列表内容
        lazy: true,
        lazyLoad(node, resolve) {
          setTimeout(() => {
            if (node.level == 0) {
              getprovince().then(response => {
                const cities = []
                for (let item of response.data) {
                  cities.push({
                    value: item.provinceCode,
                    label: item.provinceName,
                    leaf: false
                  })
                }
                resolve(cities);
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
              });
            }
            if (node.level == 1) {
              getcity(node.value).then(response => {
                const areas = [];
                for (let item of response.data) {
                  areas.push({
                    value: item.cityCode,
                    label: item.cityName,
                    leaf: false
                  })
                }
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                resolve(areas);
              });
            } else if (node.level == 2) {
              getarea(node.value).then(response => {
                const areas = []
                for (let item of response.data) {
                  areas.push({
                    value: item.areaCode,
                    label: item.areaName,
                    leaf: true
                  })
                }
                const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                Array.from($el).map((item) => item.removeAttribute('aria-owns'));
                resolve(areas);
              });
            }
          }, 100);
        }
      },
      companyScaleOptions: [],
      newForm: {
        address: [],
        addressId: [],
        companyName: '',
        companyScale: '',
        companyDuty: '',
        educationBackground: ''
      },
      changing: false,  // 加载身份
      identityType: '',
      typeList: [], // 身份类型
      positionId: "", // 职位Id
      positionSelections: [], // 职位选项
      departmentId: "", // 部门Id
      departmentSelections: [], // 部门选项
      directionHoler: "",
      userDirection: "", // 研究方向
      userIdentity: 0,
      loading: true,
      profile: {},
      positionOptions: [],
      educationOptions: [],
      multiIndustry: [], // 行业多选
      userIndustryIds: [], // 用户提交的行业
      imageUrl: "",
      imgdisplay: "",
      staticPath: "",
      btnDisabled: false,
      percentage: 100,
      ElMessage,
    };
  },
  created() {
    this.$store.commit("message/SET_ACTIVENAV", "-");
    this.$store.commit("message/SET_BG", false);
  },
  async mounted() {
    this.staticPath = staticPath;
    this.gettechserviceOptions();
    await this.initMyInfo();
  },
  methods: {
    addressChange(e){
      this.visibleChange()
      if(e?.length !== 3) return
      this.newForm.address = [this.$refs.formAddress.getCheckedNodes()[0].pathLabels[0],this.$refs.formAddress.getCheckedNodes()[0].pathLabels[1],this.$refs.formAddress.getCheckedNodes()[0].pathLabels[2]]
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    identityChange(val){
      // 身份切换更改行业
      const object = this.typeList.find((item)=>item.id === val)
      if(!object) return
      this.changing = true
      if(this.isJson(object.extendConfigJson)){
        const json = JSON.parse(object.extendConfigJson)
        if(json.dictType){
          getSelectFatherSonByType(json.dictType).then(res=>{
            this.multiIndustry = res.data;
            this.userIndustryIds = []
            this.$refs.industrys.clearResult()
          }).finally(()=>{
            this.changing = false
          })
          return
        }
      }
      getSelectFatherSonByType(6).then(res=>{
        this.multiIndustry = res.data;
        this.userIndustryIds = []
        this.$refs.industrys.clearResult()
      }).finally(()=>{
        this.changing = false
      })
    },
    async handleUpload(val) {
      var result = await this.directUpload(val.file, uploadTypeImage, true);
      this.imgdisplay = result.Location.slice(result.Location.indexOf("/"));
      this.btnDisabled = false;
    },
    directUpload(file, uploadType) {
      var self = this;
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo),
      };
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video;
      let bucket =
        uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video;
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              self.btnDisabled = true;
              self.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    labelRule() {
      // userIdentity === 0 表示实验室
      if (
        (!this.profile.email || this.profile.email.length === 0) &&
        this.userIdentity === 0
      ) {
        this.ElMessage.warning("请填入邮箱");
        return false;
      }
      let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(this.profile.email) && this.userIdentity === 0) {
        this.ElMessage.warning("邮箱格式不正确");
        return false;
      }
      return true;
    },
    checkDirection(temp) {
      // 判断研究方向
      const num = 3; // 个数
      const perLength = 10; // 每个长度
      if (!temp) {
        // 空判断
        ElMessage.warning("请输入研究方向!");
        return;
      }
      if (temp.split(",").length > num) {
        // 研究方向个数
        ElMessage.warning("研究方向最多输入" + num + "个!");
        return false;
      }
      let flag = false;
      temp.split(",").forEach((item) => {
        if (item.length > perLength) {
          flag = true;
          return true;
        }
      });
      if (flag) {
        ElMessage.warning("研究方向每个不超过" + perLength + "个字!");
        return false;
      }
      return true;
    },
    async getNewSelections() {
      // 获取新选项
      let res = await getSelectFatherSonByType(90);
      this.positionSelections = res.data;
      res = await getSelectFatherSonByType(91);
      this.departmentSelections = res.data;
    },
    isJson(str){
      if (typeof str == 'string') {
        try {
          let obj=JSON.parse(str);
          if(typeof obj == 'object' && obj ){
            return true;
          }else{
            return false;
          }
        } catch(e) {
          return false;
        }
      }
      return false
    },
    async initMyInfo() {
      getIdentityTypes().then(res=>{  // 获取所有的身份类型
        this.typeList = res.map(item=>{
          if(this.isJson(item.extendConfigJson)){
            item.iconName = JSON.parse(item.extendConfigJson).iconName
          }
          else item.iconName = ''
          return item
        })
      })
      this.profile.id = this.id;
      let id = this.id
      if(G60site){
        this.profile.id = getG60LocalInfo().memberInfo.id
        id = getG60LocalInfo().memberInfo.id
      }
      else if(TanSite){
        this.profile.id = getTanLocalInfo().memberInfo.id
        id = getTanLocalInfo().memberInfo.id
      }
      if (!id) return; // 空判断
      let res = await memberDetails(id);
      this.profile = res.data;
      this.identityType = this.profile.preselectionOrganizationTypeId || ''
      this.profile.headImage =
        res.data.headImage || "../../assets/images/avatar.jpg";
      this.imgdisplay = this.profile.headImage;
      this.profile.industryId = this.profile.industryIds?.length > 0 ? this.profile.industryIds[0] : ''; // 改为多选后，选择初始项赋值
      this.profile.label = res.data.labels ? res.data.labels.map(item=>item.val) : []
      if (this.profile.label?.length > 0) {
        // 数字科创研究方向获取
        this.userDirection = res.data.label.join(",");
      }
      this.loading = false;
      this.userIdentity = res.data.organizationType;
      if (this.userIdentity === 1) {
        this.getNewSelections();
        this.positionId = this.profile.companyPositionId
          ? this.profile.companyPositionId
          : "";
        this.departmentId = this.profile.companyDepartmentId
          ? this.profile.companyDepartmentId
          : "";
      }
      this.newForm.companyName = this.profile.unitName
      this.newForm.companyScale = this.profile.unitSize
      this.newForm.companyDuty = this.profile.newPosition
      this.newForm.educationBackground = this.profile.educationalBackground
      if(this.profile.location){
        const json = JSON.parse(this.profile.location)
        this.newForm.addressId = [json.provinceCode,json.cityCode,json.areaCode]
        this.newForm.address = [json.provinceName,json.cityName,json.areaName]
      }
      console.log(this.profile,'hehe22222');
      this.getIndustryOptions();
    },
    async saveProfile(type) {
      // null 判断
      if(type === 0){
        this.$emit('editInfo',{type:'0'})  // 关闭窗口
        return
      }
      if(!this.identityType){
        ElMessage.warning('请选择身份类型')
        return
      }
      if (
        this.profile.selfIntroduction?.length > 0 &&
        this.profile.selfIntroduction?.length < 10
      ) {
        ElMessage.warning("自我介绍最少输入10个字符!");
        return;
      }
      if (this.imageUrl) {
        this.profile.headImage = this.imgdisplay;
      }
      let notLab = false;
      let emailflag = this.labelRule();
      if (!emailflag && this.userIdentity === 0) return;
      else if (
        emailflag &&
        (this.userIdentity === 1 || this.userIdentity === null)
      ) {
        // 说明是企业或无组织用户 没有邮箱
        this.profile.email = "enterprise@email.com";
        notLab = true;
      }
      if (this.userIdentity === 0 || this.userIdentity === 1) {
        let industrys = this.$refs.industrys
          .getResult()
          ?.map((item) => item[1]); // 多选行业
        if (industrys.length === 0) {
          //  多选行业未选择，给出提示
          ElMessage.warning("请选择行业!");
          return;
        }
        this.profile.industryNames = [];
        this.profile.industryId = industrys?.length > 0 ? industrys[0] : ''; // 多选行业用初始项赋值
        this.multiIndustry.forEach((item) => {
          item.child?.find((type) => {
            if (industrys.includes(type.id)) {
              this.profile.industryNames.push(type.val);
            }
          });
        });
        this.profile.industryIds = industrys;
        this.profile.industryName = this.profile.industryNames?.length > 0 ? this.profile.industryNames[0] : '';
      } else {
        // 无身份用户
        let industrys = this.$refs.industrys
          .getResult()
          ?.map((item) => item[1]); // 多选行业
        if (industrys.length === 0) {
          //  多选行业未选择，给出提示
          ElMessage.warning("请选择行业!");
          return;
        }
        this.profile.industryNames = [];
        this.profile.industryId = industrys?.length > 0 ? industrys[0] : ''; // 多选行业用初始项赋值
        this.multiIndustry.forEach((item) => {
          item.child?.find((type) => {
            if (industrys.includes(type.id)) {
              this.profile.industryNames.push(type.val);
            }
          });
        });
        this.profile.industryIds = industrys;
        this.profile.industryName = this.profile.industryNames?.length > 0 ? this.profile.industryNames[0] : '';
      }
      // 判断 industryIds 类型
      const typeInfo = this.typeList.find((item)=>item.id + '' === this.identityType + '')
      let newDefaultIndustry = []
      if(typeInfo){
        if(this.isJson(typeInfo.extendConfigJson)){
          const json = JSON.parse(typeInfo.extendConfigJson)
          if(json.dictType && json.defaultIndustryId){
            newDefaultIndustry = [json.defaultIndustryId]
          }
        }
      }
      if (this.showNewItems) {
        // 判断新增选项
        if (!((this.positionId + "").length > 0)) {
          ElMessage.warning("请选择职位!");
          return;
        }
        if (!((this.departmentId + "").length > 0)) {
          ElMessage.warning("请选择部门!");
          return;
        }
      }
      // 2.2.7 新增字段
      if(!this.newForm.companyName){
        ElMessage.warning('请输入单位名称')
        return
      }
      else if(!this.newForm.companyDuty){
        ElMessage.warning('请输入担任职务职位')
        return
      }
      if (this.profile.label.length === 0 && this.userIdentity === 0) {
        // 实验室研发方向为空
        ElMessage.warning("请填写研发方向!");
        return;
      } else if (this.profile.label.length > 5 && this.userIdentity === 0) {
        ElMessage.warning("研发方向最多不能超过5个!");
        return;
      } else if (this.profile.label.length > 0 && this.userIdentity === 0) {
        let lengthFlag = false;
        this.profile.label.find((item) => {
          if (item.length > 40) {
            lengthFlag = true;
          }
        });
        if (lengthFlag) {
          ElMessage.warning("方向标签每个最长为40字符!");
          return;
        }
      }
      if (this.userIdentity === 1) {
        // 数字科创用户 研究方向
        if (this.userDirection.length === 0) {
          // 空
          ElMessage.warning("请输入研究方向!");
          return;
        }
        if (!this.checkDirection(this.userDirection.replace(/，/gi, ",")))
          return; // 研究方向校验
        this.userDirection = this.userDirection.replace(/，/gi, ","); // 逗号转换
        this.profile.label = this.userDirection.split(",");
      }
      this.btnDisabled = true;
      let that = this
      notLab = true // 2.2.7 不在队身份进行判断
      if (notLab) {
        let param = {
          id: this.profile.id,
          handUrl: this.profile.headImage,
          industryIds: newDefaultIndustry?.length > 0 ? newDefaultIndustry : this.profile.industryIds,
          label: this.profile.label,
          companyPositionId: this.positionId,
          companyDepartmentId: this.departmentId,
          name: this.profile.name,
          positionId: this.profile.positionId,
          selfIntroduction: this.profile.selfIntroduction,
          preselectionOrganizationTypeId: this.identityType,
          educationalBackground: this.newForm.educationBackground,
          location: this.newForm.addressId?.length === 3 ? JSON.stringify({
            provinceCode: this.newForm.addressId[0],
            provinceName: this.newForm.address[0],
            cityCode: this.newForm.addressId[1],
            cityName: this.newForm.address[1],
            areaCode: this.newForm.addressId[2],
            areaName: this.newForm.address[2],
          }) : '',
          position: this.newForm.companyDuty,
          unitName: this.newForm.companyName,
          unitSize: this.newForm.companyScale,
        };
        if(newDefaultIndustry?.length){
          param.preselectionIndustryIds = this.profile.industryIds
        }
        editProfileBasic(param)
          .then(() => {
            this.$store.dispatch("user/editUserInfo", this.profile);
            updateProfile(); // 更新腾讯个人信息
            ElMessage.success("成功修改个人资料");
            setTimeout(() => {
              // this.$router.push("/user-center");
              that.$emit('editInfo',{type:'0'})  // 关闭窗口
            }, 500);
          })
          .finally(() => {
            this.btnDisabled = false;
            this.loading = false;
          });
        // editProfile(param)
        // .then(() => {
        //   this.$store.dispatch("user/editUserInfo", this.profile);
        //   updateProfile(); // 更新腾讯个人信息
        //   ElMessage.success("成功修改个人资料");
        //   setTimeout(() => {
        //     that.$emit('editInfo',{type:'0'})  // 关闭窗口
        //   }, 500);
        // })
        // .finally(() => {
        //   this.btnDisabled = false;
        //   this.loading = false;
        // });
        return;
      }
    },
    getIndustryOptions() {
      const object = this.profile.preselectionOrganizationType
      let id = 6
      this.userIndustryIds = this.profile.industryIds
      let flag = false
      if(object && this.isJson(object.extendConfigJson)){
        const json = JSON.parse(object.extendConfigJson)
        if(json.dictType){
          id = json.dictType
          flag = true
          this.userIndustryIds = this.profile.preselectionIndustryIds
        }
      }
      getSelectFatherSonByType(id).then(res=>{
        if(flag){
          // 新身份，获取id
        }
        this.multiIndustry = res.data;
      })
    },
    gettechserviceOptions() {
      getSelectFatherSonByType(10028).then(res=>{
        this.companyScaleOptions = res.data.map((item)=>{
          item.id = item.id + ''
          return item
        })
      })
    },
    async loadJsonFromFile(file) {
      this.imageUrl = file;
    },
  },
};
</script>

<style scoped lang="scss">
:deep() .el-card{
    border: none;
    .el-card__body{
        padding: 0;
    }
}
.edit-container {
  .card-top {
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  width: 100%;
  background: rgb(243, 243, 243);
  display: flex;
  justify-content: center;
  .card-content {
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 60px;
    .bottom-buttons{
      width: 100%;
      position:absolute;
      bottom: 0;
      left: 0;
      .cancel{
        width: 200px;
        height: 50px;
        background: #999999;
        color: #ffffff;
        border-radius: 4px;
      }
      .cancel:hover{
        color: #ffffff !important;
      }
      .confirm{
        width: 200px;
        color: #ffffff;
        height: 50px;
        background: $--color-theme;
        border-radius: 4px;
      }
      .confirm:hover{
        color: #ffffff !important;
      }
    }
    .el-form-content {
      padding-top: 20px;
    }
    .form-label {
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 20px;
    }
  }
}
.width360 {
  width: 340px;
}
.avator-center {
  display: flex;
  margin: 15px 0;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .avatar-button{
    border: none;
    background: none;
    color: #0F9FFD;
  }
}
.avatar-fix {
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
